import { ResellerItem } from 'api-client';
import { resellerApi, Roles } from 'api/api';
import ContactForm from 'components/ContactForm/ContactForm';
import InputField from 'components/InputField/InputField';
import ResellerCard from 'components/ResellerCard/ResellerCard';
import Button from 'components/Button/Button';
import SummaryPageSection from 'components/SummaryPageSection/SummaryPageSection';
import { useCallback, useMemo, useState } from 'react';
import { PageName } from 'components/ProgressBar/ProgressBar';
import ArrowLeftIcon from 'components/Icons/ArrowLeftIcon';

export interface Props {
  setPageCallback(page: PageName): void;
}

export const useHasRole = (role: Roles) => {
  const { data: roles } = resellerApi.useGetRolesQuery();
  const hasRole = useMemo(() => roles?.includes(role), [roles, role]);
  return hasRole;
};

export const SendLeadScreen = ({ setPageCallback }: Props) => {
  const [postalCode, setPostalCode] = useState('');
  const [resellers, setResellers] = useState<Array<ResellerItem>>([]);
  const [selectedResellerId, setSelectedResellerId] = useState<string>();
  const [selectedResellerName, setSelectedResellerName] = useState<string>();
  const setPostalCodeCallback = useCallback(
    (newPostalCode: string | number) => {
      setPostalCode(newPostalCode as string);
    },
    []
  );

  const [getResellers, { isFetching: resellersLoading }] =
    resellerApi.useLazyGetResellersQuery();

  const hasResellerSearch = useHasRole('B2CShowResellerOption');
  const findResellers = useCallback(async () => {
    const postalCodeNumber = Number(postalCode.replace(/\s/g, ''));
    if (isNaN(postalCodeNumber)) {
      console.error('Could not parse postal code', postalCode);
      return;
    }
    const resellerResponse = await getResellers({ zipCode: postalCodeNumber });

    if (resellerResponse.data?.resellerList)
      setResellers(resellerResponse.data?.resellerList);
  }, [getResellers, postalCode]);

  const [leadSent, setLeadSent] = useState(false);
  const leadSentCallback = useCallback(() => {
    setLeadSent(true);
  }, []);

  return !leadSent ? (
    <div className="tw-relative tw-flex tw-w-full tw-flex-col tw-justify-evenly md:tw-flex-row md:tw-p-10">
      <div className="tw-top-4 tw-left-0 tw-mt-4 md:tw-absolute md:tw-mt-0">
        <Button
          onClick={() => setPageCallback('summary')}
          variant="outline"
          noPadding
        >
          <ArrowLeftIcon className="tw-scale-150" />
        </Button>
      </div>
      {hasResellerSearch && (
        <>
          <div className="tw-px-2 md:tw-basis-4/12">
            <SummaryPageSection
              title="Hitta återförsäljare"
              description="Hitta en återförsäljare nära dig genom att ange ditt postnummer nedan."
            >
              <div className="tw-flex tw-flex-grow tw-flex-col tw-justify-end">
                <InputField
                  name="postal-code-input"
                  onChange={setPostalCodeCallback}
                  type="text"
                  label="Ange postnummer"
                />
              </div>
              <div className="tw-mt-4">
                <Button
                  onClick={findResellers}
                  fullWidth
                  loading={resellersLoading}
                >
                  Hitta nära mig
                </Button>
              </div>
            </SummaryPageSection>
          </div>
          <div className="tw-mt-4 tw-h-96 tw-px-2 md:tw-mt-0 md:tw-h-auto md:tw-basis-4/12">
            <SummaryPageSection
              title="Välj en återförsäljare"
              description="Välj den återförsäljare som du vill skicka ditt underlag som förfrågan till."
              disabled={!resellers || resellers.length === 0}
            >
              <div>
                {resellers.map(r => (
                  <div className="tw-mt-1" key={r.resellerId}>
                    <ResellerCard
                      reseller={r}
                      setSelected={setSelectedResellerId}
                      setSelectedName={setSelectedResellerName}
                      selected={selectedResellerId === r.resellerId}
                    />
                  </div>
                ))}
              </div>
            </SummaryPageSection>
          </div>
        </>
      )}
      <div className=" tw-mt-4 tw-px-2 sm:tw-basis-10/12 md:tw-mt-0 md:tw-basis-8/12 lg:tw-basis-6/12">
        <SummaryPageSection
          title="Hur vill du bli kontaktad"
          description="Ange hur du vill bli kontaktad av din återförsäljare genom att fylla i ditt mobilnummer och/eller din mailadress. Använd meddelande rutan om du vill skriva ett meddelande till återförsäljaren."
          disabled={hasResellerSearch && !selectedResellerId}
        >
          <div className="tw-flex tw-flex-grow tw-flex-col tw-justify-end">
            <ContactForm
              leadSentCallback={leadSentCallback}
              selectedReseller={selectedResellerId}
              selectedResellerName={selectedResellerName}
              requireReseller={hasResellerSearch}
            />
          </div>
        </SummaryPageSection>
      </div>
    </div>
  ) : (
    <div className="tw-flex tw-w-full tw-items-center tw-justify-center">
      <div className="tw-basis-80">
        <div className="tw-text-3xl tw-font-bold">Tack för din förfrågan!</div>
        <div className="tw-mt-7 tw-text-sm tw-text-secondary">
          Elitfönsters återförsäljare kommer nu arbeta fram ett prisförslag
          utifrån din förfrågan och kontakta dig så snart som möjligt.
        </div>{' '}
        <div className="tw-mt-4 tw-text-sm tw-text-secondary">
          <Button onClick={() => setPageCallback('summary')} fullWidth>
            Tillbaka
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SendLeadScreen;
