import { ConfigItemData } from 'api-client';
import { FormRadioButtonList } from 'components/RadioButtonList/FormRadioButtonList';
import { WindowCreatorFormState } from 'components/WindowCreator/RowFormPages';
import { FieldPath } from 'react-hook-form';
import {
  AccordionConfigComponentProps,
  AccordionConfigComponent,
} from './AccordionConfigComponent';

export type RadioButtonAccordionConfigProps = Omit<
  AccordionConfigComponentProps,
  'data' | 'children'
> & {
  data?: ConfigItemData;
  name: FieldPath<WindowCreatorFormState>;
};
export const RadioButtonAccordionConfig = ({
  name,
  data,
  ...rest
}: RadioButtonAccordionConfigProps) => (
  <AccordionConfigComponent data={data}>
    <FormRadioButtonList
      large
      name={name}
      radioOptions={data?.data ?? []}
      {...rest}
    />
  </AccordionConfigComponent>
);
