import SVGIcon, { IconProps } from './Icon';
import classNames from 'classnames';

export const CenteredSpinner = ({
  wrapperClassName,
  size = 24,
  ...rest
}: IconProps & { wrapperClassName?: string }) => (
  <div
    className={classNames(
      'tw-flex tw-w-full tw-items-center tw-justify-center',
      wrapperClassName
    )}
  >
    <div
      className={
        size === 48
          ? `tw-h-[68px]`
          : size === 96
          ? `tw-h-[136px]`
          : `tw-h-[34px]`
      }
    >
      <SpinnerIcon {...rest} size={size} />
    </div>
  </div>
);

export const SpinnerIcon = ({ className, ...rest }: IconProps) => (
  <SVGIcon
    {...rest}
    className={'tw-animate-spin ' + (className ?? '')}
    color="none"
  >
    <circle
      className="tw-opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    />
    <path
      className="tw-opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </SVGIcon>
);
export default SpinnerIcon;
