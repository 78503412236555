import AddProductButton from 'components/AddProductButton/AddProductButton';
import Button from 'components/Button/Button';
import ConfigurationDetailsComponent from 'components/ConfigurationDetailsComponent/ConfigurationDetailsComponent';
import { FormInformationTabsComponent } from 'components/InformationTabsComponent/InformationTabsComponent';
import { PageName } from 'components/ProgressBar/ProgressBar';

interface Props {
  prevScreenCallback(): void;
  setPageCallback(page: PageName): void;
}

export const ConfigurationDetailsScreen = ({
  setPageCallback,
  prevScreenCallback,
}: Props) => {
  return (
    <>
      <div className="tw-hidden tw-min-w-0 tw-flex-grow md:tw-order-2 md:tw-ml-8 md:tw-flex md:tw-shrink-0 md:tw-flex-grow-0 md:tw-basis-96">
        <FormInformationTabsComponent />
      </div>
      <div className="tw-flex tw-h-full tw-flex-col tw-justify-between md:tw-order-1 md:tw-grow">
        <div className="tw-overflow-y-auto">
          <ConfigurationDetailsComponent />
        </div>
        <div className="tw-mt-2 tw-grow-0 md:tw-flex">
          <Button variant="secondary" fullWidth onClick={prevScreenCallback}>
            Föregående
          </Button>
          <AddProductButton
            fullWidth
            setPageCallback={setPageCallback}
            className="tw-mt-2 md:tw-mt-0 md:tw-ml-2"
          />
        </div>
      </div>
    </>
  );
};
