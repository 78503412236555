import { ProspectRow } from 'api-client';
import { AccessoryNames } from 'components/AccordionConfigComponent/StockItemConfigComponent';
import { get } from 'lodash';
import './ProductSpecificationComponent.scss';

const LabeledValue = ({
  label,
  value,
  postfix,
}: {
  label: string;
  value?: string | number | null;
  postfix?: string;
}) => {
  return (
    <div className="tw-mt-2 tw-text-secondary first:tw-mt-0">
      <span className="tw-mr-1 tw-font-bold">{label}:</span>
      <span>{`${value}${postfix ? postfix : ''}`}</span>
    </div>
  );
};

const informationOrder: Array<
  [string, keyof ProspectRow | `${AccessoryNames}.name`, string?]
> = [
  ['Produktfamilj', 'productfamilyName'],
  ['Produkt', 'modelName'],
  ['Bredd', 'width'],
  ['Höjd', 'height'],
  ['Spröjs', 'sashModelName'],
  ['Ytbehandling (trä)', 'insideCoatingName'],
  ['Ytbehandling', 'outsideCoatingName'],
  ['Glas', 'glassName'],
  ['Ventil', 'airVentName'],
  ['Hängning', 'hangingName'],
  ['Handtag', 'handleName'],
  ['Gångjärn', 'hingeName'],
  ['Myggnät', 'mosquitoNetName'],
  ['Linjering', 'alignmentName'],
  ['Solskydd', 'sunscreen.name'],
  ['Utvändigt foder', 'exteriorLining.name'],
  ['Invändigt foder', 'interiorLining.name'],
  ['Fönsterbleck', 'windowSill.name'],
  ['Totalt pris', 'price', ' kr'],
];

export type Props = {
  row: ProspectRow;
};

export const ProductSpecificationComponent = ({ row }: Props) => {
  return (
    <div className="ProductSpecificationComponent tw-h-full tw-overflow-y-auto">
      {informationOrder.map(i =>
        get(row, i[1]) ? (
          <LabeledValue
            key={i[0]}
            label={i[0]}
            value={get(row, i[1]) as string | number}
            postfix={i[2]}
          />
        ) : null
      )}
    </div>
  );
};

export default ProductSpecificationComponent;
