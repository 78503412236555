import SashModelSelector from 'components/SashModelSelector/SashModelSelector';
import { FormWindowImageRenderer } from '../WindowImageRenderer';

export const SashScreen = () => (
  <>
    <div className="md:tw-flex md:tw-shrink-0 md:tw-grow md:tw-flex-col">
      <div className="screen-description tw-hidden tw-shrink-0 tw-font-semibold md:tw-block">
        Välj spröjs eller poster
      </div>
      <div className="tw-min-h-0 tw-grow md:tw-mt-2">
        <SashModelSelector />
      </div>
    </div>
    <div className="tw-hidden tw-min-w-0 tw-flex-grow md:tw-ml-8 md:tw-flex md:tw-shrink-0 md:tw-flex-grow-0 md:tw-basis-96">
      <FormWindowImageRenderer />
    </div>
  </>
);
