import { ProspectRow, ROTEnum } from 'api-client';
import { prospectApi } from 'api/api';
import Button from 'components/Button/Button';
import { PageName } from 'components/ProgressBar/ProgressBar';
import ProspectAmountSummary, {
  useProspectFromState,
} from 'components/ProspectAmountSummary/ProspectAmountSummary';
import ProspectSummary from 'components/ProspectSummary/ProspectSummary';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { useCallback } from 'react';
import { appSlice, useAppDispatch, useAppSelector } from 'store';
import { convertToProspectRequest } from '../../../utils/convertToProspectRequest';
import { useHasRole } from './SendLeadScreen';

export interface Props {
  setPageCallback(page: PageName): void;
  resetEventsSentCallback(): void;
}
export const SummaryScreen = ({
  setPageCallback,
  resetEventsSentCallback,
}: Props) => {
  const dispatch = useAppDispatch();
  const { prospect, isFetching } = useProspectFromState();
  const currentFamily = useAppSelector(state => state.app.currentFamily);

  const [updateProspect, updateStatus] =
    prospectApi.useUpdateProspectROTvalueMutation();
  const newProductCallback = useCallback(() => {
    setPageCallback(currentFamily ? 'product' : 'style');
    resetEventsSentCallback();
    dispatch(appSlice.actions.setRow());
  }, [setPageCallback, currentFamily, resetEventsSentCallback, dispatch]);

  const editRowCallback = useCallback(
    (row: ProspectRow) => {
      setPageCallback('function');
      dispatch(appSlice.actions.setRow(row));
      dispatch(appSlice.actions.setCurrentFamily(row.productfamilyid));
    },
    [dispatch, setPageCallback]
  );
  const updateROTcallback = useCallback(
    (newROTValue: boolean) => {
      if (!prospect) return;
      updateProspect({
        prospect: {
          ...convertToProspectRequest(prospect),
          useROT: newROTValue ? ROTEnum._1 : ROTEnum._0,
        },
      });
    },
    [prospect, updateProspect]
  );

  const hasInstallation = useHasRole('Installation');
  return (
    <>
      <div className="tw-flex tw-h-full tw-flex-col tw-justify-between md:tw-shrink-0 md:tw-grow">
        <ProspectSummary editRowCallback={editRowCallback} />
        <div className="tw-mt-4 tw-grow-0 md:tw-mt-0 md:tw-flex md:tw-items-end md:tw-justify-between">
          <div className="tw-grow tw-px-8 md:tw-order-2">
            {hasInstallation && (
              <div className="tw-mb-4">
                <ToggleSwitch
                  onChange={updateROTcallback}
                  label="Använd ROT-avdrag"
                  initialValue={!!prospect?.useROT}
                />
              </div>
            )}
            <ProspectAmountSummary
              isUpdating={updateStatus?.isLoading || isFetching}
            />
          </div>
          <div className="tw-mt-4 tw-grow md:tw-order-1 md:tw-mt-0">
            <Button onClick={newProductCallback} fullWidth>
              Lägg till fler produkter
            </Button>
          </div>
        </div>
      </div>
      <div className="tw-flex tw-h-full tw-flex-col tw-justify-between md:tw-ml-8 md:tw-shrink-0 md:tw-basis-4/12 md:tw-px-4">
        <div className="tw-grow">
          <div className="tw-mt-4 tw-text-lg tw-font-semibold md:tw-mt-0 lg:tw-text-xl">
            Sammanställning över dina produkter
          </div>
          <div className="tw-text-sm tw-text-secondary lg:tw-text-base">
            Med ikonerna för raden kan du, se, ta bort eller redigera dina val,
            du kan även justera antalet. Vill du fortsätt att lägga till fler
            produkter använd knappen Lägg till fler produkter.
          </div>
          <div className="tw-text-lg tw-font-semibold md:tw-mt-4 lg:tw-text-xl">
            Nästa steg
          </div>
          <div className="tw-text-sm tw-text-secondary lg:tw-text-base">
            När du är klar med dina produktval kan du välja skicka en förfrågan
            till en återförsäljare nära dig för att få ett prisförslag. Du kan
            även välja att spara dina produkter för att fortsätta senare.
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-justify-between">
          <div>
            <Button onClick={() => setPageCallback('save')} fullWidth>
              Spara och fortsätt senare
            </Button>
          </div>
          {/* <div className="tw-mt-4">
            <Button onClick={() => setPageCallback('save')} fullWidth>
              Ladda ner / Skriv ut
            </Button>
          </div> */}
          <div className="tw-mt-2">
            <Button
              onClick={() => setPageCallback('send-lead')}
              fullWidth
              disabled={prospect !== undefined && prospect?.rows?.length === 0}
            >
              Skicka förfrågan
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SummaryScreen;
