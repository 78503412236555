import { ConfigItemData, StockItemData } from 'api-client';
import Accordion, { AccordionProps } from 'components/Accordion/Accordion';
import AccordionContent from 'components/Accordion/AccordionContent/AccordionContent';
import { ReactNode } from 'react';

export function isStockItem(
  data?: ConfigItemData | StockItemData
): data is StockItemData {
  return (data as StockItemData)?.stockItemData !== undefined;
}

export type AccordionConfigComponentProps = Omit<AccordionProps, 'heading'> & {
  data?: ConfigItemData | StockItemData;
  hideIfOne?: boolean;
  children: ReactNode;
};
export const AccordionConfigComponent = ({
  data,
  hideIfOne,
  children,
  ...rest
}: AccordionConfigComponentProps) => {
  // Hide Accordion if we
  // 1. Have no data
  // 2. If we only have one option and have specified "hideIfOne"

  const options = isStockItem(data) ? data.stockItemData : data?.data;

  if (!options) return null;
  return !(hideIfOne && options.length <= 1) ? (
    <Accordion heading={data?.title} {...rest}>
      <AccordionContent
        description={data?.description}
        popupInfo={data?.infoPopup?.body}
      >
        {children}
      </AccordionContent>
    </Accordion>
  ) : null;
};

export default AccordionConfigComponent;
