import { FormProductScroller } from 'components/ProductScroller/FormProductScroller';
import SectionComponent from 'components/SectionComponent/SectionComponent';
import { useMemo } from 'react';
import { useAppSelector } from 'store';
import { useIsMd } from 'utils/useIsMd';
import { useModelsDataFromForm } from './ModelScreen';
import { Product } from 'components/ProductCard/ProductCard';

export const ModelCategoryScreen = () => {
  const isMd = useIsMd();
  const initModelCategory = useAppSelector(
    state => state.app.row?.modelCategoryId
  );
  const initModelId = useAppSelector(state => state.app.row?.modelId);
  const modelsData = useModelsDataFromForm();
  const modelCategoryOptions = useMemo(
    () =>
      (modelsData?.modelCategory.modelCategories
        .map(mc =>
          mc.id
            ? {
                name: mc.name,
                value: mc.id,
                image: mc.image,
                description: mc.description,
              }
            : undefined
        )
        .filter(mc => mc) as Product[]) || [],
    [modelsData]
  );
  const initalModelCategoryValue = useMemo(
    () =>
      initModelCategory ??
      modelsData?.models.data.find(m => m.value == initModelId)?.categories[0],
    [modelsData, initModelCategory, initModelId]
  );
  return modelsData ? (
    <>
      <div className="md:tw-shrink-0 md:tw-basis-72">
        <div className="screen-description tw-hidden tw-font-semibold md:tw-block">
          Välj produktkategori
        </div>
      </div>
      <div className="tw-min-w-0 md:tw-ml-8 md:tw-flex-grow">
        <SectionComponent
          title={'Produktkategori'}
          number={4}
          classNames="tw-mt-8 md:tw-mt-0"
          hideHeading={isMd}
          hideNumber={isMd}
        >
          <div className="tw--ml-6 tw-w-[calc(100%_+_3rem)] md:tw-ml-0 md:tw-w-auto">
            <FormProductScroller
              name="modelCategory"
              products={modelCategoryOptions}
              hideDescription={!isMd}
              initialValue={initalModelCategoryValue}
            />
          </div>
        </SectionComponent>
      </div>
    </>
  ) : null;
};
