import { renderWindowAsBase64 } from '@ef/ef-component-library';
import { ProspectRowRequest } from 'api-client';
import productsApi, { prospectApi } from 'api/api';
import Button, { ButtonProps } from 'components/Button/Button';
import { PageName } from 'components/ProgressBar/ProgressBar';
import { useCallback } from 'react';
import { actions, useAppDispatch, useAppSelector } from 'store';
import { useWindowFormContext } from 'utils/useWindowFormContext';
import './AddProductButton.scss';

export interface Props extends Omit<ButtonProps, 'onClick'> {
  setPageCallback(page: PageName): void;
}
export const AddProductButton = ({ setPageCallback, ...buttonRest }: Props) => {
  const [createProspect, { isLoading: creatingProspect }] =
    prospectApi.useCreateProspectMutation();
  const [createProspectRow, { isLoading: creatingRow }] =
    prospectApi.useCreateProspectRowMutation();
  const [updateProspectRow, { isLoading: updatingRow }] =
    prospectApi.useUpdateProspectRowMutation();
  const [getImageJSon, { isLoading: fetchingImage }] =
    productsApi.useLazyGetImageJsonQuery();

  const dispatch = useAppDispatch();

  const { watch } = useWindowFormContext();
  const [prospectRow] = watch(['prospectRow']);
  const prospectId = useAppSelector(state => state.app.prospectId);
  const prospectRowId = useAppSelector(state => state.app.row?.rowId);
  const onButtonClick = useCallback(async () => {
    let currentProspectId = prospectId;

    if (prospectRow) {
      if (!currentProspectId) {
        await createProspect()
          .unwrap()
          .then(p => {
            console.log(p);
            currentProspectId = p.prospectId!;
            dispatch(actions.setProspectId(currentProspectId));
          })
          .catch();
      }
      const { data: imageData, isError } = await getImageJSon(prospectRow);

      let outsideBase64;
      let insideBase64;
      if (isError) {
        console.error('Could not fetch image');
      } else {
        const imgSize = 250;
        outsideBase64 = renderWindowAsBase64(
          false,
          JSON.parse(JSON.stringify(imageData)),
          imgSize,
          imgSize
        );
        insideBase64 = renderWindowAsBase64(
          true,
          JSON.parse(JSON.stringify(imageData)),
          imgSize,
          imgSize
        );
      }
      const rowWithImg: ProspectRowRequest = {
        ...prospectRow,
        renderedImage: {
          imageBack_Base64: insideBase64,
          imageFront_Base64: outsideBase64,
        },
      };
      if (currentProspectId && prospectRowId) {
        updateProspectRow({
          prospectId: currentProspectId,
          rowId: prospectRowId,
          row: rowWithImg,
        })
          .unwrap()
          .then(() => {
            dispatch(actions.setRow());
          });
      } else if (currentProspectId) {
        await createProspectRow({
          prospectId: currentProspectId,
          row: rowWithImg,
        }).unwrap();
      }
      setPageCallback('summary');
    }
  }, [
    prospectId,
    prospectRow,
    getImageJSon,
    prospectRowId,
    setPageCallback,
    createProspect,
    dispatch,
    updateProspectRow,
    createProspectRow,
  ]);

  return (
    <Button
      onClick={onButtonClick}
      loading={creatingProspect || creatingRow || updatingRow || fetchingImage}
      {...buttonRest}
    >
      {prospectRowId ? 'Uppdatera produkt' : 'Lägg till produkt'}
    </Button>
  );
};

export default AddProductButton;
