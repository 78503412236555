import { ProspectRow } from 'api-client';
import ProductSpecificationComponent from 'components/ProductSpecificationComponent/ProductSpecificationComponent';
import TabViewer from 'components/TabViewer/TabViewer';
import WindowImageRenderer from 'components/WindowImageRenderer/WindowImageRenderer';
import { useConfiguredRow } from 'utils/useConfiguredRow';
import './InformationTabsComponent.scss';

export type Props = {
  row?: ProspectRow;
};
export const FormInformationTabsComponent = () => {
  const { data: row } = useConfiguredRow();
  if (!row) return null;
  return <InformationTabsComponent row={row} />;
};
export const InformationTabsComponent = ({ row }: Props) => {
  if (!row) return null;
  return (
    <TabViewer
      tabs={[
        {
          label: 'Bild',
          content: (
            <div className="tw-h-full tw-w-full">
              <WindowImageRenderer row={row} />
            </div>
          ),
        },
        {
          label: 'Dina val',
          content: (
            <div className="tw-h-full tw-w-full">
              <ProductSpecificationComponent row={row} />
            </div>
          ),
        },
      ]}
    />
  );
};

export default InformationTabsComponent;
