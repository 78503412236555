import classnames from 'classnames';
import ChevronRightIcon from 'components/Icons/ChevronRightIcon';
import { Fragment } from 'react';
import './ProgressBar.scss';

export type PageName =
  | 'splash'
  | 'style'
  | 'productCategory'
  | 'product'
  | 'size'
  | 'sashModel'
  | 'function'
  | 'summary'
  | 'save'
  | 'send-lead';

export type ConfigStatusType = 'Saved' | 'New';

export type Page = {
  name: PageName;
  label: string;
};

export interface Props {
  pages: Page[];
  currentPage?: string;
}

export const ProgressBar = ({ pages, currentPage }: Props) => (
  <div className="ProgressBar tw-flex tw-justify-between tw-text-base">
    <label
      className={classnames({
        'tw-font-bold tw-text-progressBarText tw-underline tw-decoration-4 tw-underline-offset-4':
          pages[0].name === currentPage,
      })}
      key={`first`}
      role="listitem"
    >
      {pages[0].label}
    </label>
    {pages.slice(1).map(p => (
      <Fragment key={p.name}>
        <ChevronRightIcon
          className={classnames({
            'tw-text-progressBarText': p.name === currentPage,
          })}
        />
        <label
          className={classnames({
            'tw-font-bold tw-text-progressBarText tw-underline tw-decoration-2 tw-underline-offset-4':
              p.name === currentPage,
          })}
          role="listitem"
        >
          {p.label}
        </label>
      </Fragment>
    ))}
  </div>
);

export default ProgressBar;
