import { ImageData } from 'api-client';
import classNames from 'classnames';
import './ImageContainer.scss';

export interface Props {
  image?: ImageData;
  imageUri?: string;
  cover?: boolean;
  fallbackSrc?: string;
  size?: number;
}
export const ImageContainer = ({
  image,
  imageUri,
  cover,
  fallbackSrc,
  size,
}: Props) => {
  const imgSrc = `${image?.uri || imageUri}${size ? `?w=${size}` : ''}`;
  return (
    <div className="ImageContainer">
      {imgSrc && (
        <img
          src={imgSrc ? imgSrc : fallbackSrc}
          alt={image?.name}
          className={classNames({
            'tw-object-cover': cover,
            'tw-object-contain': !cover,
          })}
        />
      )}
    </div>
  );
};

export default ImageContainer;
