import { prospectApi } from 'api/api';
import classNames from 'classnames';
import { ConfigStatusType, PageName } from 'components/ProgressBar/ProgressBar';
import SplashScreen from 'components/SplashScreen/SplashScreen';
import { SaveScreen } from 'components/WindowImageRenderer/Screens/SaveScreen';
import { SendLeadScreen } from 'components/WindowImageRenderer/Screens/SendLeadScreen';
import { SummaryScreen } from 'components/WindowImageRenderer/Screens/SummaryScreen';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, appSlice, useAppSelector } from 'store';
import { AnalyticsObject, sendEvent } from 'utils/analytics';
import { RowFormPageComponent, rowFormPages } from './RowFormPages';
import './WindowCreator.scss';

const rowFormPageNames = Object.values(rowFormPages).map(p => p.name);
const allPages: Array<PageName> = ['splash', ...rowFormPageNames, 'summary'];
export const WindowCreator = () => {
  const [currentPage, setCurrentPage] = useState<PageName>('splash');
  const [trackingConfigStatus, setTrackingConfigStatus] =
    useState<ConfigStatusType>('New');
  const [eventsSent, setEventsSent] = useState<PageName[]>([]);
  const dispatch = useAppDispatch();

  const [getProspectByCode, { isLoading }] =
    prospectApi.useLazyGetProspectByCodeQuery();

  const nextCallback = useCallback(() => {
    setCurrentPage(curPage => {
      const currentIndex = allPages.findIndex(p => p === curPage);
      return allPages[currentIndex + 1];
    });
  }, []);
  const prevCallback = useCallback(() => {
    setCurrentPage(curPage => {
      const currentIndex = allPages.findIndex(p => p === curPage);
      return allPages[currentIndex - 1];
    });
  }, []);

  useEffect(() => {
    if (!eventsSent.includes(currentPage) && currentPage != 'splash') {
      const analyticsObject: AnalyticsObject = {
        configStatus: trackingConfigStatus,
      };
      sendEvent('build_window_' + currentPage, undefined, analyticsObject);
      setEventsSent(prevEventsSent => [...prevEventsSent, currentPage]);
    }
  }, [currentPage, eventsSent, trackingConfigStatus]);

  const resetEventsSent = () => {
    setEventsSent([]);
    setTrackingConfigStatus('New');
  };
  const fetchProspectFromCode = useCallback(
    async (code: string) => {
      const r = await getProspectByCode(code);
      if (r.data) {
        dispatch(appSlice.actions.setProspectId(r.data.prospectId!));

        if (setTrackingConfigStatus) {
          setTrackingConfigStatus('Saved');
        }
        setCurrentPage('summary');

        return true;
      } else {
        console.error('No prospect found', code);
        return false;
      }
    },
    [dispatch, getProspectByCode, setTrackingConfigStatus]
  );

  const queryCode = useAppSelector(state => state.app.code);

  useEffect(() => {
    if (queryCode) {
      fetchProspectFromCode(queryCode);
    }
  }, [queryCode, fetchProspectFromCode]);
  return (
    <div
      className={classNames(
        'WindowCreator tw-flex tw-flex-col tw-justify-between tw-py-8 tw-px-4 md:tw-h-[42rem] md:tw-px-10'
      )}
    >
      {currentPage === 'splash' ? (
        <div className="content-area tw-grow tw-overflow-x-visible md:tw-overflow-hidden">
          <div
            className={classNames('tw-h-full', {
              'tw-hidden': currentPage !== 'splash',
            })}
          >
            <SplashScreen
              setPageCallback={setCurrentPage}
              isLoadingProspect={isLoading}
              fetchProspectFromCode={fetchProspectFromCode}
            />
          </div>
        </div>
      ) : rowFormPageNames.includes(currentPage) ? (
        <RowFormPageComponent
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          prevCallback={prevCallback}
          nextCallback={nextCallback}
        />
      ) : (
        <div
          className={classNames(
            'tw-flex tw-min-w-0 tw-flex-col md:tw-h-full md:tw-flex-row md:tw-p-4'
          )}
        >
          {currentPage === 'save' ? (
            <SaveScreen setPageCallback={setCurrentPage} />
          ) : currentPage === 'summary' ? (
            <SummaryScreen
              resetEventsSentCallback={resetEventsSent}
              setPageCallback={setCurrentPage}
            />
          ) : currentPage === 'send-lead' ? (
            <SendLeadScreen setPageCallback={setCurrentPage} />
          ) : null}
        </div>
      )}
    </div>
  );
};
export default WindowCreator;
