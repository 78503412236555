import { Product } from 'components/ProductCard/ProductCard';
import { useCallback } from 'react';
import { useController } from 'react-hook-form';
import { useWindowFormContext } from 'utils/useWindowFormContext';
import ProductScroller, { BaseProductScrollerProps } from './ProductScroller';

export interface FormProductScrollerProps
  extends Omit<BaseProductScrollerProps, 'onChange' | 'findInitialItem'> {
  name: 'prospectRow.productfamilyid' | 'prospectRow.modelId' | 'modelCategory';
  initialValue?: number;
  onChangeCallback?(product: Product): void;
}
export const FormProductScroller = ({
  name,
  initialValue,
  products,
  onChangeCallback,
  ...rest
}: FormProductScrollerProps) => {
  const { control } = useWindowFormContext();
  const {
    field: { onChange, value },
    fieldState,
  } = useController({
    name,
    control,
  });

  const findInitialItem = useCallback(() => {
    const defaultValue = initialValue || value;
    return defaultValue
      ? products.find(o => o.value === defaultValue) || products[0]
      : products[0];
  }, [initialValue, products, value]);

  const internalOnChangeCallback = useCallback(
    (selectedItem: Product) => {
      if (selectedItem && selectedItem.value != value) {
        onChange(selectedItem.value);
        onChangeCallback?.(selectedItem);
      }
    },
    [onChange, onChangeCallback, value]
  );
  return (
    <>
      <ProductScroller
        onChange={internalOnChangeCallback}
        products={products}
        findInitialItem={findInitialItem}
        fieldState={fieldState}
        {...rest}
      />
    </>
  );
};
