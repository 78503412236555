import { InfoIcon } from 'components/Icons';
import Modal from 'components/Modal/Modal';
import { ReactNode } from 'react';
import { useIsMd } from 'utils/useIsMd';
import './AccordionContent.scss';

export interface Props {
  title?: string;
  popupInfo?: ReactNode;
  description?: string;
}
export const AccordionContent: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  description,
  popupInfo,
  children,
}) => {
  const isMd = useIsMd();
  return (
    <div className="AccordionContent tw-bg-white tw-px-4 tw-pb-10">
      {title && <h4 className="tw-mb-4 tw-font-semibold">{title}</h4>}
      <p className="tw-mb-4 tw-flex tw-items-center tw-text-xs">
        {description}{' '}
        {popupInfo && (
          <Modal
            title={title}
            trigger={<InfoIcon />}
            content={popupInfo}
            buttonClass="tw-ml-2"
            modal={!isMd}
          />
        )}
      </p>
      {children}
    </div>
  );
};

export default AccordionContent;
