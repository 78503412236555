import { ConfigData } from 'api-client';
import classNames from 'classnames';
import ImageContainer from 'components/ImageContainer/ImageContainer';
import './RadioButton.scss';
export interface Props {
  option: ConfigData;
  isSelected: boolean;
  onSelectClick: () => void;
  large?: boolean;
}

export const RadioButton = ({
  option,
  large,
  isSelected,
  onSelectClick,
}: Props) => {
  return (
    <div
      className={classNames(
        'RadioButton tw-flex tw-cursor-pointer tw-items-center sm:tw-inline-flex',
        {
          'tw-flex-col': large,
          'tw-bg-selected ': isSelected,
        }
      )}
      onClick={onSelectClick}
    >
      <div
        className={classNames({
          'tw-bg-selected': !option.image?.uri,
          'tw-rounded-full': !option.image?.uri && !large,
          'tw-h-[12rem] tw-w-full': large,
          'tw-ml-2 tw-h-10 tw-w-10': !large,
        })}
        title={option.title ?? undefined}
        data-selected={isSelected}
      >
        <ImageContainer image={option.image} />
      </div>
      <div className={classNames('tw-p-4', { 'tw-font-semibold': isSelected })}>
        {option.title}
        {option.price !== 0 && (
          <div className="tw-mt-1 tw-flex tw-w-full tw-justify-center tw-font-normal">
            {`( +${option.price} kr )`}
          </div>
        )}
      </div>
    </div>
  );
};

export default RadioButton;
