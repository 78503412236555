import {
  configureStore,
  createSelector,
  createSlice,
  isRejectedWithValue,
  Middleware,
  PayloadAction,
} from '@reduxjs/toolkit';
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query';
import { ErrorResponseModel, ProspectRow } from 'api-client';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { productsApi, prospectApi, resellerApi } from './api/api';

export type AppState = {
  prospectId?: string;
  currentFamily?: number;
  row?: ProspectRow;
  gtmId?: string | null;
  apiUrl?: string | null;
  apiKey?: string | null;
  theme?: string;
  code?: string;
};
const container = document.getElementById('root');
const params = new URLSearchParams(window.location.search);
const initialState: AppState = {
  gtmId:
    (process.env.REACT_APP_GA_TAG_MANAGER_ID ||
      container?.getAttribute('data-gtmId') ||
      params.get('gtmId')) ??
    undefined,
  apiUrl:
    (process.env.REACT_APP_API_URL ||
      container?.getAttribute('data-api') ||
      params.get('api')) ??
    undefined,
  apiKey:
    (process.env.REACT_APP_API_KEY ||
      container?.getAttribute('data-key') ||
      params.get('key')) ??
    undefined,
  theme:
    (process.env.REACT_APP_THEME ||
      container?.getAttribute('theme') ||
      params.get('theme')) ??
    undefined,
  code:
    (process.env.REACT_APP_CODE ||
      container?.getAttribute('code') ||
      params.get('code')) ??
    undefined,
};
export const createAppSlice = (initialAppState?: Partial<AppState>) =>
  createSlice({
    name: 'app',
    initialState: initialAppState ?? ({} as AppState),
    reducers: {
      setProspectId: (state, action: PayloadAction<string>) => {
        state.prospectId = action.payload;
      },
      setCurrentFamily: (state, action: PayloadAction<number | undefined>) => {
        state.currentFamily = action.payload;
      },
      setRow: (state, action: PayloadAction<ProspectRow | undefined>) => {
        state.row = action.payload;
      },
      setApiCredentials: (
        state,
        action: PayloadAction<{ url: string; key: string }>
      ) => {
        state.apiUrl = action.payload.url;
        state.apiKey = action.payload.key;
      },
    },
  });

export const appSlice = createAppSlice(initialState);
export const { actions } = appSlice;

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware = () => next => action => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    const errorModel = action.payload?.data as ErrorResponseModel;
    console.error('API Error', errorModel);
    toast.error(errorModel?.userErrorMsg);
  }
  return next(action);
};

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [productsApi.reducerPath]: productsApi.reducer,
    [prospectApi.reducerPath]: prospectApi.reducer,
    [resellerApi.reducerPath]: resellerApi.reducer,
    app: appSlice.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      rtkQueryErrorLogger,
      productsApi.middleware,
      prospectApi.middleware,
      resellerApi.middleware
    ),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const selectTheme = createSelector(
  (state: RootState) => state.app,
  appState => `ef-theme${appState.theme ? ' ' + appState.theme : ''}`
);
