import { SpinnerIcon } from 'components/Icons';
import { ReactElement, useCallback, useEffect } from 'react';
import { Provider } from 'react-redux';
import {
  actions,
  selectTheme,
  store,
  useAppDispatch,
  useAppSelector,
} from 'store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WindowCreator from 'components/WindowCreator/WindowCreator';
import TagManager from 'react-gtm-module';

export const ApiCredentialsFetcher = ({
  children,
}: {
  children: ReactElement;
}) => {
  const apiUrl = useAppSelector(state => state.app.apiUrl);
  const apiKey = useAppSelector(state => state.app.apiKey);
  const gtmId = useAppSelector(state => state.app.gtmId);
  const dispatch = useAppDispatch();
  const setConfig = useCallback(
    (apiUrl: string, apiKey: string, gtmId: string) => {
      TagManager.initialize({ gtmId: gtmId || gtmId });
      dispatch(
        actions.setApiCredentials({
          url: apiUrl,
          key: apiKey,
        })
      );
    },
    [dispatch]
  );
  useEffect(() => {
    if (!apiUrl || !apiKey || !gtmId) {
      fetch('appSettings.json')
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          setConfig(
            apiUrl ?? data.REACT_APP_API_URL,
            apiKey ?? data.REACT_APP_API_KEY,
            gtmId ?? data.REACT_APP_GA_TAG_MANAGER_ID
          );
        })
        .catch(function (err) {
          console.log('error: ' + err);
        });
    } else {
      setConfig(apiUrl, apiKey, gtmId);
    }
  }, [apiKey, apiUrl, gtmId, setConfig]);
  return apiUrl && apiKey ? children : <SpinnerIcon />;
};

const ThemedApp = () => {
  const theme = useAppSelector(selectTheme);
  return (
    <div className={theme}>
      <WindowCreator />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export const App = () => {
  return (
    <Provider store={store}>
      <ApiCredentialsFetcher>
        <ThemedApp />
      </ApiCredentialsFetcher>
    </Provider>
  );
};

export default App;
