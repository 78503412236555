import { ColorConfigData } from 'api-client';
import './ColorSquare.scss';
export interface Props {
  color: ColorConfigData;
  onSelectClick(): void;
  isSelected: boolean;
}

export const ColorSquare = ({ color, onSelectClick, isSelected }: Props) => (
  <div
    className={`ColorSquare tw-h-10 tw-w-10 tw-cursor-pointer ${
      isSelected ? 'tw-border-4 tw-border-primary' : ''
    }`}
    title={color.title}
    style={{ backgroundColor: color.code }}
    onClick={onSelectClick}
    data-selected={isSelected}
  />
);

export default ColorSquare;
