import { ProspectRow } from 'api-client';
import { ProspectRowRequest } from 'api-client/models/ProspectRowRequest';
import { prospectApi } from 'api/api';
import { pick } from 'lodash';
import {
  useWindowFormContext,
  hasBaseRowInfo,
} from 'utils/useWindowFormContext';

export const prospectRowRequestKeys: Array<keyof ProspectRowRequest> = [
  'quantity',
  'alternativeId',
  'productfamilyid',
  'modelId',
  'sashModelId',
  'width',
  'height',
  'insideCoatingId',
  'outsideCoatingId',
  'handleId',
  'hingeId',
  'airVentId',
  'alignmentId',
  'glassId',
  'mosquitoNetId',
  'sunscreen',
  'floor',
];

export const convertToProspectRowRequest = (
  row: ProspectRow
): ProspectRowRequest => pick(row, prospectRowRequestKeys);

export const useConfiguredRow = () => {
  const { watch } = useWindowFormContext();
  const [prospectRow] = watch(['prospectRow']);
  const result = prospectApi.useGetNewProspectRowQuery(
    { ...prospectRow },
    {
      skip: !hasBaseRowInfo(prospectRow),
    }
  );

  return result;
};
