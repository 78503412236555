import { GetSashModelResponse } from './../api-client/models/GetSashModelResponse';
import { ProspectRowRequest } from './../api-client/models/ProspectRowRequest';
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import {
  ResellerResponse,
  GetConfigDataResponse,
  ModelsResponse,
  ProductFamiliesResponse,
  Prospect,
  ProspectRow,
  ValidSizeResponse,
  ProspectRequest,
} from 'api-client';
import { WindowRenderData } from '@ef/ef-component-library';
import { RootState } from 'store';
export type Roles = 'Installation' | 'B2CShowResellerOption' | 'B2CShowPrice';

const getDynamicBaseQuery =
  (
    path: string
  ): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =>
  async (args, WebApi, extraOptions) => {
    const baseUrl = (WebApi.getState() as RootState).app.apiUrl;
    const apiKey = (WebApi.getState() as RootState).app.apiKey;
    const rawBaseQuery = fetchBaseQuery({
      baseUrl: `${baseUrl}/${path}` ?? '',
      prepareHeaders: headers => {
        headers.set('x-api-key', `${apiKey}`);
        return headers;
      },
    });
    return rawBaseQuery(args, WebApi, extraOptions);
  };

export interface ValidateWindowSizeRequest {
  modelId?: number;
  width?: number;
}

export const productsApi = createApi({
  reducerPath: 'productsApi',
  baseQuery: getDynamicBaseQuery('Products'),
  endpoints: builder => ({
    getProductFamilies: builder.query<ProductFamiliesResponse, void>({
      query: () => '',
    }),
    getModels: builder.query<ModelsResponse, number | undefined>({
      query: productFamilyId => ({
        url: `/${productFamilyId}`,
      }),
    }),
    getImageJson: builder.query<WindowRenderData, ProspectRowRequest>({
      query: row => ({
        url: '/RenderImage',
        body: row,
        method: 'POST',
      }),
      transformResponse: (response: { result: WindowRenderData }) =>
        response.result,
    }),
    getConfigData: builder.query<GetConfigDataResponse, ProspectRowRequest>({
      query: rowParams => ({
        method: 'POST',
        body: rowParams,
        url: `/ConfigData`,
      }),
    }),
    getSashModelData: builder.query<GetSashModelResponse, ProspectRowRequest>({
      query: rowParams => ({
        method: 'POST',
        body: rowParams,
        url: `/SashData`,
      }),
    }),
    validateWindowSize: builder.query<
      ValidSizeResponse,
      ValidateWindowSizeRequest
    >({
      query: ({ modelId, width }) => ({ url: `/${modelId}/${width}` }),
    }),
  }),
});

export const resellerApi = createApi({
  reducerPath: 'resellerApi',
  baseQuery: getDynamicBaseQuery('Reseller'),
  endpoints: builder => ({
    getRoles: builder.query<Roles[], void>({
      query: () => '/GetRoles',
    }),
    getResellers: builder.query<
      ResellerResponse,
      { zipCode: number; radius?: number }
    >({
      query: ({ zipCode, radius = 25 }) => ({
        url: `/ResellerList`,
        params: { zipCode, radius },
      }),
    }),
  }),
});

export const prospectApi = createApi({
  reducerPath: 'prospectApi',
  tagTypes: ['Prospect', 'ProspectRow', 'ProspectCode'],
  baseQuery: getDynamicBaseQuery('Prospect'),
  endpoints: builder => ({
    getNewProspectRow: builder.query<ProspectRow, ProspectRowRequest>({
      query: row => ({
        url: `/ProspectRow/Configure`,
        method: 'POST',
        body: row,
      }),
    }),
    createProspect: builder.mutation<Prospect, void>({
      query: () => ({
        url: '',
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'Prospect', id: 'LIST' }],
    }),
    getProspect: builder.query<Prospect, string>({
      query: id => `/${id}`,
      providesTags: (result, error, id) => [{ type: 'Prospect', id }],
    }),
    getProspectByCode: builder.query<Prospect, string>({
      query: code => `/Code/${code}`,
    }),
    getProspectCode: builder.query<string, string>({
      query: id => ({
        url: `/${id}/Code`,
        responseHandler: response => response.text(),
      }),
      providesTags: (result, error, id) => [{ type: 'ProspectCode', id }],
    }),
    updateProspectROTvalue: builder.mutation<
      Prospect,
      { prospect: ProspectRequest }
    >({
      query: ({ prospect }) => ({
        url: `/${prospect.prospectId}`,
        method: 'PUT',
        body: prospect,
      }),
      invalidatesTags: (result, error, { prospect: { prospectId } }) => [
        { type: 'Prospect', id: prospectId },
      ],
    }),
    createProspectRow: builder.mutation<
      ProspectRow,
      { prospectId: string; row: ProspectRowRequest }
    >({
      query: ({ row, prospectId }) => ({
        url: `/${prospectId}/ProspectRow`,
        method: 'POST',
        body: row,
      }),
      invalidatesTags: (result, error, { prospectId }) => [
        { type: 'Prospect', id: prospectId },
      ],
    }),
    updateProspectRow: builder.mutation<
      ProspectRow,
      { prospectId: string; row: ProspectRowRequest; rowId: string }
    >({
      query: ({ row, prospectId, rowId }) => ({
        url: `/${prospectId}/ProspectRow/${rowId}`,
        method: 'PUT',
        body: row,
      }),
      invalidatesTags: (result, error, { prospectId, rowId }) => [
        { type: 'Prospect', id: prospectId },
        { type: 'ProspectRow', id: rowId },
      ],
    }),
    deleteProspectRow: builder.mutation<
      void,
      { prospectId: string; rowId: string }
    >({
      query: ({ rowId, prospectId }) => ({
        url: `/${prospectId}/ProspectRow/${rowId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { prospectId, rowId }) => [
        { type: 'Prospect', id: prospectId },
        { type: 'ProspectRow', id: rowId },
      ],
    }),
    sendLead: builder.mutation<void, Prospect>({
      query: prospect => ({
        url: `/Lead`,
        method: 'POST',
        body: prospect,
      }),
      invalidatesTags: (result, error, prospect) => [
        { type: 'Prospect', id: prospect.prospectId },
      ],
    }),
    sendProspectCode: builder.mutation<
      string,
      { prospectId: string; email: string }
    >({
      query: ({ prospectId, email }) => ({
        url: `/${prospectId}/Send`,
        method: 'POST',
        body: {
          email,
        },
      }),
    }),
  }),
});

export default productsApi;
