import './RadioButtonList.scss';
import { useCallback, useEffect, useState } from 'react';
import RadioButton from 'components/RadioButtonList/RadioButton/RadioButton';
import { ConfigData } from 'api-client';
import classNames from 'classnames';

export interface Props {
  radioOptions: ConfigData[];
  onChange(value: ConfigData | undefined | null): void;
  findInitialItem?(): ConfigData | undefined;
  large?: boolean;
}

export const RadioButtonList = ({
  radioOptions,
  onChange,
  findInitialItem,
  large,
}: Props) => {
  const [selectedItem, setSelectedItem] = useState<ConfigData>();

  const onItemClick = useCallback(
    (item: ConfigData) => {
      setSelectedItem(item);
      onChange(item);
    },
    [onChange]
  );

  useEffect(() => {
    if (!radioOptions.find(p => p === selectedItem)) {
      const i = findInitialItem?.() ?? radioOptions[0];

      setSelectedItem(i);
      onChange(i);
    }
  }, [radioOptions, selectedItem, findInitialItem, onChange]);
  return (
    <div
      className={classNames('RadioButtonList tw-grid tw-gap-2', {
        'tw-grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))]': !large,
        'tw-grid-cols-[repeat(auto-fill,_minmax(12rem,_1fr))]': large,
      })}
    >
      {radioOptions.map(option => (
        <RadioButton
          large={large}
          key={`${option.id}`}
          option={option}
          onSelectClick={() => onItemClick(option)}
          isSelected={selectedItem === option}
        />
      ))}
    </div>
  );
};

export default RadioButtonList;
