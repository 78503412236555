import { ProspectRowRequest } from 'api-client/models/ProspectRowRequest';
import classNames from 'classnames';
import Button from 'components/Button/Button';
import { FormInformationTabsComponent } from 'components/InformationTabsComponent/InformationTabsComponent';
import ProgressBar, {
  Page,
  PageName,
} from 'components/ProgressBar/ProgressBar';
import { ConfigurationDetailsScreen } from 'components/WindowImageRenderer/Screens/ConfigurationDetailsScreen';
import { ModelCategoryScreen } from 'components/WindowImageRenderer/Screens/ModelCategoryScreen ';
import { ModelScreen } from 'components/WindowImageRenderer/Screens/ModelScreen';
import { SashScreen } from 'components/WindowImageRenderer/Screens/SashScreen';
import { SizeScreen } from 'components/WindowImageRenderer/Screens/SizeScreen';
import { StyleScreen } from 'components/WindowImageRenderer/Screens/StyleScreen';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppSelector } from 'store';
import { convertToProspectRowRequest } from 'utils/useConfiguredRow';
import { useIsMd } from 'utils/useIsMd';
import { useCurrentPageIsValid } from './utils/useCurrentPageIsValid';
import { useIsCheckingValidity } from './utils/useIsCheckingValidity';
export const rowFormPages: Page[] = [
  { name: 'style', label: 'Stil' },
  { name: 'productCategory', label: 'Produktkategori' },
  { name: 'product', label: 'Produkt' },
  { name: 'size', label: 'Storlek' },
  { name: 'sashModel', label: 'Spröjs' },
  { name: 'function', label: 'Funktion & Utseende' },
];

const FormNavigationNextButton = ({
  nextCallback,
  currentPage,
}: {
  nextCallback(): void;
  currentPage: PageName;
}) => {
  const isValid = useCurrentPageIsValid(currentPage);

  const isLoading = useIsCheckingValidity();
  return (
    <Button onClick={nextCallback} disabled={!isValid} loading={isLoading}>
      Nästa
    </Button>
  );
};

export const nextRequirements: Partial<Record<PageName, string>> = {
  style: 'prospectRow.productfamilyid',
  product: 'prospectRow.modelId',
  productCategory: 'modelCategory',
};

export type WindowCreatorFormState = {
  style: number;
  material: number;
  modelCategory: number;
  dividerType: number;
  prospectRow: ProspectRowRequest;
  stringPlaceHolder: string;
};
export const RowFormPageComponent = ({
  currentPage,
  prevCallback,
  setCurrentPage,
  nextCallback,
}: {
  currentPage: PageName;
  prevCallback(): void;
  nextCallback(): void;
  setCurrentPage(page: PageName): void;
}) => {
  const isMd = useIsMd();
  const row = useAppSelector(state => state.app.row);
  const currentFamily = useAppSelector(state => state.app.currentFamily);
  const formMethods = useForm<WindowCreatorFormState>({
    defaultValues: {
      style: undefined,
      material: undefined,
      modelCategory: undefined,
      prospectRow: row
        ? convertToProspectRowRequest(row)
        : { productfamilyid: currentFamily },
    },
    mode: 'onChange',
  });

  return (
    <FormProvider {...formMethods}>
      <div
        className={classNames(
          'tw-flex tw-grow-0 tw-flex-col lg:tw-flex-row lg:tw-justify-between'
        )}
      >
        <div className="tw-text-2xl tw-font-bold lg:tw-mr-8 lg:tw-basis-72">
          Bygg ditt fönster
        </div>
        <div className="tw-hidden tw-pt-2 md:tw-block md:tw-flex-grow">
          <ProgressBar pages={rowFormPages} currentPage={currentPage} />
        </div>
      </div>
      <div className="tw-flex-grow md:tw-overflow-hidden">
        <div className="content-area tw-h-full tw-grow tw-overflow-x-visible md:tw-overflow-hidden">
          <div
            className={classNames('md:tw-flex md:tw-pt-4', {
              'md:tw-hidden': isMd && currentPage !== 'style',
            })}
          >
            <StyleScreen />
          </div>
          <div
            className={classNames('md:tw-flex md:tw-pt-4', {
              'md:tw-hidden': isMd && currentPage !== 'productCategory',
            })}
          >
            <ModelCategoryScreen />
          </div>
          <div
            className={classNames('md:tw-flex md:tw-pt-4', {
              'md:tw-hidden': isMd && currentPage !== 'product',
            })}
          >
            <ModelScreen />
          </div>
          <div
            className={classNames(
              'tw-sticky tw-top-0 tw-z-10 tw-mt-8 tw--ml-6 tw-w-[calc(100%_+_3rem)] md:tw-hidden'
            )}
          >
            <div className="tw-h-80 tw-max-h-[30vh] tw-w-full">
              <FormInformationTabsComponent />
            </div>
          </div>
          <div
            className={classNames('md:tw-flex md:tw-h-full md:tw-pt-4', {
              'md:tw-hidden': isMd && currentPage !== 'size',
            })}
          >
            <SizeScreen />
          </div>
          <div
            className={classNames('md:tw-flex md:tw-h-full md:tw-pt-4', {
              'md:tw-hidden': isMd && currentPage !== 'sashModel',
            })}
          >
            <SashScreen />
          </div>
          <div
            className={classNames('md:tw-flex md:tw-h-full md:tw-pt-4', {
              'md:tw-hidden': isMd && currentPage !== 'function',
            })}
          >
            <ConfigurationDetailsScreen
              setPageCallback={setCurrentPage}
              prevScreenCallback={prevCallback}
            />
          </div>
        </div>
      </div>
      <div
        className={classNames(
          'tw-mt-4 tw-hidden tw-grow-0 tw-justify-between tw-text-lg tw-text-white md:tw-flex',
          { 'md:tw-hidden': currentPage === 'function' }
        )}
      >
        {currentPage !== 'style' ? (
          <Button onClick={prevCallback}>Föregående</Button>
        ) : (
          <div />
        )}
        <FormNavigationNextButton
          nextCallback={nextCallback}
          currentPage={currentPage}
        />
      </div>
    </FormProvider>
  );
};
