import { pick, at } from 'lodash';
import { ProspectRowRequest } from './../api-client/models/ProspectRowRequest';
import { useFormContext } from 'react-hook-form';
import { WindowCreatorFormState } from 'components/WindowCreator/RowFormPages';
export const useWindowFormContext = () => {
  const context = useFormContext<WindowCreatorFormState>();
  return context;
};

export const requiredSashFields: Array<keyof ProspectRowRequest> = [
  'productfamilyid',
  'modelId',
  'width',
  'height',
];
export const hasRequiredSashFields = (prospectRow?: ProspectRowRequest) =>
  prospectRow && at(prospectRow, requiredSashFields).every(v => v);

export const requiredBaseRowFields = [...requiredSashFields, 'sashModelId'];
export const pickBaseRowInfo = (prospectRow?: ProspectRowRequest) =>
  pick(prospectRow, requiredBaseRowFields);
export const hasBaseRowInfo = (prospectRow?: ProspectRowRequest) =>
  prospectRow && at(prospectRow, requiredBaseRowFields).every(v => v);
