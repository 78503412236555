import productsApi from 'api/api';
import { PageName } from 'components/ProgressBar/ProgressBar';
import { useState } from 'react';
import { get } from 'react-hook-form';
import { useConfiguredRow } from 'utils/useConfiguredRow';
import {
  hasBaseRowInfo,
  useWindowFormContext,
} from 'utils/useWindowFormContext';
import { nextRequirements } from '../RowFormPages';

export const useCurrentPageIsValid = (currentPage: PageName) => {
  const [errorPage, setErrorPage] = useState<PageName>();
  const { watch } = useWindowFormContext();
  const formState = watch();

  const { isError: isRowError, isFetching, data: row } = useConfiguredRow();
  const { isError: isImageError } = productsApi.useGetImageJsonQuery(
    { ...row },
    { skip: isFetching || isRowError || !hasBaseRowInfo(formState.prospectRow) }
  );
  const isValid = nextRequirements[currentPage]
    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      get(formState, nextRequirements[currentPage]!) &&
      !isRowError &&
      !isImageError
    : !isRowError && !isImageError;
  if (!isValid && errorPage == currentPage) {
    return false;
  } else if (!isValid && errorPage == undefined) {
    setErrorPage(currentPage);
    return false;
  } else if (isValid && errorPage == currentPage) {
    setErrorPage(undefined);
    return true;
  } else {
    return true;
  }
};
