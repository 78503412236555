import { ModelDataDetails } from 'api-client';
import productsApi from 'api/api';
import { FormProductScroller } from 'components/ProductScroller/FormProductScroller';
import SectionComponent from 'components/SectionComponent/SectionComponent';
import { useCallback, useMemo } from 'react';
import { useAppSelector } from 'store';
import { useIsMd } from 'utils/useIsMd';
import { useWindowFormContext } from 'utils/useWindowFormContext';

export const useModelsDataFromForm = () => {
  const { watch } = useWindowFormContext();
  const [productFamilyId] = watch(['prospectRow.productfamilyid']);
  const { data: modelsData } = productsApi.endpoints.getModels.useQuery(
    productFamilyId,
    {
      skip: !productFamilyId,
    }
  );
  return modelsData;
};

export const ModelScreen = () => {
  const isMd = useIsMd();
  const modelsData = useModelsDataFromForm();
  const { watch, resetField, setValue } = useWindowFormContext();
  const [modelCategory] = watch(['modelCategory']);
  const filteredModels = useMemo(
    () =>
      modelsData?.models.data.filter(
        m => !modelCategory || m.categories.includes(modelCategory)
      ) || [],
    [modelCategory, modelsData?.models.data]
  );

  const initialModeld = useAppSelector(state => state.app.row?.modelId);

  const resetCallback = useCallback(
    (model: ModelDataDetails) => {
      resetField('prospectRow.width', {
        defaultValue: model?.size.defaultWidth,
      });
      resetField('prospectRow.height', {
        defaultValue: model?.size.defaultHeight,
      });
      setValue('prospectRow.sashModelId', undefined);
    },
    [setValue, resetField]
  );

  return modelsData ? (
    <>
      {isMd && (
        <div className="md:tw-shrink-0 md:tw-basis-72">
          <div className="screen-description tw-hidden tw-font-semibold md:tw-block">
            Välj produkt - antal öppningar (luft)
          </div>
        </div>
      )}
      <div className="tw-min-w-0 md:tw-ml-8 md:tw-flex-grow">
        <SectionComponent
          title={modelsData.models.title}
          number={5}
          classNames="tw-mt-8 md:tw-mt-0"
          hideHeading={isMd}
          hideNumber={isMd}
        >
          <div className="tw--ml-6 tw-w-[calc(100%_+_3rem)] md:tw-ml-0 md:tw-w-auto">
            <FormProductScroller
              products={filteredModels}
              name="prospectRow.modelId"
              hideDescription={!isMd}
              onChangeCallback={resetCallback}
              initialValue={initialModeld}
            />
          </div>
        </SectionComponent>
      </div>
    </>
  ) : null;
};
