import Button from 'components/Button/Button';
import { AddIcon, RemoveIcon } from 'components/Icons';
import { useState } from 'react';
import './Accordion.scss';

export interface AccordionProps {
  heading?: string;
  initialIsActive?: boolean;
  closeButtonLabel?: string;
  closeButtonCallback?(): void;
}

export const Accordion: React.FC<React.PropsWithChildren<AccordionProps>> = ({
  children,
  heading,
  initialIsActive,
  closeButtonCallback,
  closeButtonLabel,
}) => {
  const [isActive, setIsActive] = useState(initialIsActive);

  const closeButton = () => {
    setIsActive(false);
    closeButtonCallback?.();
  };

  return (
    <div className="Accordion tw-border tw-border-white">
      <div className="accordion-item">
        <div
          className={`accordion-title tw-flex tw-cursor-pointer tw-flex-row tw-justify-between tw-p-4 ${
            isActive ? 'tw-bg-white' : 'tw-bg-lightGray'
          }`}
          onClick={() => setIsActive(!isActive)}
        >
          <span className="tw-text-lg tw-font-semibold">{heading}</span>
          {isActive ? <RemoveIcon /> : <AddIcon />}
        </div>
        {isActive && (
          <div className="tw-flex tw-flex-col">
            {children}
            {closeButtonLabel && (
              <div className="tw-mr-2 tw-mb-2 tw-flex tw-justify-end md:tw-hidden">
                <Button onClick={closeButton}>{closeButtonLabel}</Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Accordion;
