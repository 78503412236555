import { ProspectRow } from 'api-client';
import { prospectApi } from 'api/api';
import { CenteredSpinner } from 'components/Icons';
import { useProspectFromState } from 'components/ProspectAmountSummary/ProspectAmountSummary';
import ProspectRowSummary from 'components/ProspectRowSummary/ProspectRowSummary';
import { useCallback } from 'react';
import './ProspectSummary.scss';

export type Props = {
  editRowCallback(row: ProspectRow): void;
};
export const ProspectSummary = ({ editRowCallback }: Props) => {
  const { prospect, isLoading } = useProspectFromState();

  const [updateProspectRow] = prospectApi.useUpdateProspectRowMutation();
  const [deleteProspectRow] = prospectApi.useDeleteProspectRowMutation();
  const editCallback = useCallback(
    (rowId: string) => {
      const row = prospect?.rows?.find(r => r.rowId === rowId);
      if (prospect && row) {
        editRowCallback(row);
      }
    },
    [editRowCallback, prospect]
  );
  const changeAmountCallback = useCallback(
    (rowId: string, newQuantity: number) => {
      const row = prospect?.rows?.find(r => r.rowId === rowId);
      if (prospect && row) {
        updateProspectRow({
          prospectId: prospect.prospectId!,
          rowId,
          row: { ...row, quantity: newQuantity },
        });
      }
    },
    [prospect, updateProspectRow]
  );
  const deleteCallback = useCallback(
    (rowId: string) =>
      prospect?.prospectId &&
      deleteProspectRow({ prospectId: prospect.prospectId, rowId }),
    [deleteProspectRow, prospect?.prospectId]
  );
  return (
    <div className="ProspectSummary tw-flex tw-flex-col tw-overflow-hidden">
      <div className="tw-w-full tw-text-center tw-text-xl tw-font-semibold">
        Sammanställning
      </div>
      <div className="tw-flex-grow-1 tw-overflow-auto">
        {isLoading ? (
          <CenteredSpinner size={48} wrapperClassName="tw-mt-8" />
        ) : (
          <>
            {prospect?.rows?.map(r => (
              <div key={r.rowId} className="tw-mt-2 ">
                <ProspectRowSummary
                  prospectRow={r}
                  changeAmount={changeAmountCallback}
                  deleteCallback={deleteCallback}
                  editCallback={editCallback}
                />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default ProspectSummary;
