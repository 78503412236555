import { prospectApi } from 'api/api';
// import InputField from 'components/InputField/InputField';
import Button from 'components/Button/Button';
import SummaryPageSection from 'components/SummaryPageSection/SummaryPageSection';
// import { useCallback, useState } from 'react';
import { useAppSelector } from 'store';
import { PageName } from 'components/ProgressBar/ProgressBar';
import ArrowLeftIcon from 'components/Icons/ArrowLeftIcon';
import { useState, useCallback } from 'react';
import InputField from 'components/InputField/InputField';
import { SpinnerIcon } from 'components/Icons';

export interface Props {
  setPageCallback(page: PageName): void;
}
export const SaveScreen = ({ setPageCallback }: Props) => {
  const prospectId = useAppSelector(state => state.app.prospectId);

  /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
  const { data: code } = prospectApi.useGetProspectCodeQuery(prospectId!, {
    skip: !prospectId,
  });

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const setEmailCallback = useCallback((newEmail: string | number) => {
    setEmail(newEmail as string);
  }, []);

  const [sendProspectRow, { isLoading, isSuccess }] =
    prospectApi.useSendProspectCodeMutation();
  const sendCode = useCallback(async () => {
    if (prospectId) {
      if (email) {
        await sendProspectRow({ prospectId, email });
        setEmailError('');
      } else {
        setEmailError('Epostadress saknas');
      }
    }
  }, [email, prospectId, sendProspectRow]);
  return (
    <div className="tw-relative tw-grow md:tw-flex md:tw-items-center md:tw-justify-center">
      <div className="tw-top-4 tw-left-0 tw-mt-4 md:tw-absolute md:tw-mt-0">
        <Button
          onClick={() => setPageCallback('summary')}
          variant="outline"
          noPadding
        >
          <ArrowLeftIcon className="tw-scale-150" />
        </Button>
      </div>
      <div className="tw-shrink-0 tw-grow-0 tw-basis-80 md:tw-h-72">
        <SummaryPageSection
          title="Spara kod"
          description="Din produktval sparas med hjälp en kod. Du kan när som helst öppna dina produktval och fortsätta eller skicka in till en av våra återförsäljare."
        >
          <div className="tw-flex tw-justify-center tw-bg-lightGray tw-px-16 tw-py-2 tw-text-lg tw-font-semibold">
            {code ? `Din kod: ${code}` : <SpinnerIcon size={24} />}
          </div>
        </SummaryPageSection>
      </div>
      <div className="tw-mt-14 tw-shrink-0 tw-grow-0 tw-basis-80 md:tw-ml-16 md:tw-mt-0 md:tw-flex md:tw-h-72 md:tw-flex-col md:tw-justify-between">
        <SummaryPageSection
          title="Skicka kod"
          description="För att enklare komma ihåg koden kan du maila till dig själv. Du kan även dela dina val via mail med någon annan."
        >
          <div>
            <InputField
              name="email-input"
              onChange={setEmailCallback}
              type="text"
              label="Ange epostadress"
              error={emailError}
            />
          </div>
          <div className="tw-mt-4">
            <Button onClick={sendCode} loading={isLoading}>
              Skicka kod
            </Button>
          </div>
        </SummaryPageSection>
      </div>
    </div>
  );
};
