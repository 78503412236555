import productsApi from 'api/api';
import { pick } from 'lodash';
import { useEffect, useState } from 'react';
import { useConfiguredRow } from 'utils/useConfiguredRow';
import { useDebounce } from 'utils/useDbounce';
import {
  hasRequiredSashFields,
  requiredSashFields,
  useWindowFormContext,
} from 'utils/useWindowFormContext';

export const useIsCheckingValidity = () => {
  const { watch } = useWindowFormContext();
  const { prospectRow } = watch();

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [debouncedIsFetching, setDebouncedIsFetching] = useDebounce<boolean>(
    isFetching,
    500
  );
  const { isFetching: isFetchingModels } =
    productsApi.endpoints.getModels.useQuery(prospectRow?.productfamilyid, {
      skip: !prospectRow?.productfamilyid,
    });

  const { isFetching: isFetchingSashes } = productsApi.useGetSashModelDataQuery(
    pick(prospectRow, requiredSashFields),
    {
      skip: !hasRequiredSashFields(prospectRow),
    }
  );

  const { isFetching: isFetchingRow } = useConfiguredRow();

  useEffect(() => {
    const tempFetching = isFetchingModels || isFetchingSashes || isFetchingRow;
    if (tempFetching) setDebouncedIsFetching(tempFetching);
    setIsFetching(tempFetching);
  }, [
    isFetchingRow,
    isFetchingModels,
    isFetchingSashes,
    setDebouncedIsFetching,
  ]);

  return debouncedIsFetching;
};
