import { ConfigData } from 'api-client';
import { WindowCreatorFormState } from 'components/WindowCreator/RowFormPages';
import { useCallback } from 'react';
import { FieldPath, useFormContext, useController } from 'react-hook-form';
import RadioButtonList, {
  Props as BaseRadioButtonListProps,
} from './RadioButtonList';

interface Props extends Omit<BaseRadioButtonListProps, 'onChange'> {
  name: FieldPath<WindowCreatorFormState>;
  initialValue?: ConfigData;
}

export const FormRadioButtonList = ({
  name,
  radioOptions,
  initialValue,
  ...rest
}: Props) => {
  const { control } = useFormContext<WindowCreatorFormState>();
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });
  const findInitialItem = useCallback(() => {
    const defaultValue = initialValue?.id || value;
    if (defaultValue) {
      return radioOptions.find(o => o.id === defaultValue);
    }
  }, [initialValue?.id, value, radioOptions]);

  const onChangeCallback = useCallback(
    (selectedItem: ConfigData) => {
      if (selectedItem) {
        onChange(selectedItem.id);
      }
    },
    [onChange]
  );

  return (
    <>
      <RadioButtonList
        radioOptions={radioOptions}
        onChange={onChangeCallback}
        findInitialItem={findInitialItem}
        {...rest}
      />
    </>
  );
};
