import MeasureWindowImage from 'assets/img/measure-window.png';
import Accordion from 'components/Accordion/Accordion';
import AccordionContent from 'components/Accordion/AccordionContent/AccordionContent';
import ImageContainer from 'components/ImageContainer/ImageContainer';
import { FormInputField } from 'components/InputField/FormInputField';
import SizeSection from 'components/SizeSection/SizeSection';
import TabViewer from 'components/TabViewer/TabViewer';
import { useAppSelector } from 'store';
import { useIsMd } from 'utils/useIsMd';
import { FormWindowImageRenderer } from '../WindowImageRenderer';
import { useModelsDataFromForm } from './ModelScreen';
import { useHasRole } from './SendLeadScreen';
const SizeInstructions = () => (
  <div>
    <div>
      <strong>Så här mäter du din fönsterprodukt</strong>
    </div>
    <div>
      <div className="tw-mt-2">1. Du skall mäta två mått, bredd och höjd</div>
      <div className="tw-mt-2">
        2. Du mäter enkelt produkten genom att ta måttet från karmens innerkant
        till den andra karmens innerkant. För att underlätta kan det vara bra
        att öppna fönstret. <br />
      </div>
      <div className="tw-mt-2">
        3, Addera sedan 100 mm (50 mm på varje sida) till dina mått innan du
        knappar in dessa i fönsterbyggaren, annars kommer fönstren bli för små.
        Bredd = X mm + 100 mm, Höjd = y + 100 mm.
      </div>
      <div className="tw-mt-2">
        <a
          href="https://youtu.be/zc5-ywb3_1Y"
          className="tw-underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          Förklarande film på youtube
        </a>
      </div>
    </div>
  </div>
);

export const SizeScreen = () => {
  const isMd = useIsMd();
  const modelsData = useModelsDataFromForm();
  const initialProductWidth = useAppSelector(state => state.app.row?.width);
  const initialProductHeight = useAppSelector(state => state.app.row?.height);

  const hasInstallation = useHasRole('Installation');
  return modelsData ? (
    <>
      <div className="md:tw-shrink-0 md:tw-basis-96">
        <div className="screen-description tw-hidden tw-font-semibold md:tw-block">
          Ange bredd och höjd för produkten
        </div>
        <SizeSection
          initWidth={initialProductWidth}
          initHeight={initialProductHeight}
          modelsData={modelsData}
          isMd={isMd}
        />
        {hasInstallation && (
          <FormInputField
            name="prospectRow.floor"
            label="Våning"
            type="text"
            className="tw-mt-2"
            debounce={500}
          />
        )}
      </div>
      <div className="tw-hidden lg:tw-flex">
        <div className="md:tw-ml-8 md:tw-shrink-0 md:tw-flex-grow">
          <TabViewer
            tabs={[
              {
                label: 'Ditt fönster',
                content: (
                  <div className="tw-h-80 tw-w-80">
                    <FormWindowImageRenderer />
                  </div>
                ),
              },
              {
                label: 'Förklarande bild',
                content: (
                  <div className="tw-h-80 tw-w-80">
                    <ImageContainer imageUri={MeasureWindowImage} />
                  </div>
                ),
              },
            ]}
          />
        </div>
        <div className="md:tw-ml-8 md:tw-flex-grow">
          <SizeInstructions />
        </div>
      </div>
      <div className="tw-hidden md:tw-ml-8 md:tw-block md:tw-h-full md:tw-flex-grow lg:tw-hidden">
        <TabViewer
          tabs={[
            {
              label: 'Ditt fönster',
              content: (
                <div className="tw-h-full tw-w-full">
                  <FormWindowImageRenderer />
                </div>
              ),
            },
            {
              label: 'Instruktioner',
              content: <SizeInstructions />,
            },
            {
              label: 'Förklarande bild',
              content: <ImageContainer imageUri={MeasureWindowImage} />,
            },
          ]}
        />
      </div>
      <div className="tw-mt-4 md:tw-hidden">
        <Accordion heading={'Instruktioner för storlek'}>
          <AccordionContent>
            <SizeInstructions />
            <ImageContainer imageUri={MeasureWindowImage} />
          </AccordionContent>
        </Accordion>
      </div>
    </>
  ) : null;
};
