import { Prospect, ProspectRequest } from 'api-client';
import { pick } from 'lodash';

export const prospectRequestKeys: Array<keyof ProspectRequest> = [
  'customerEmail',
  'customerName',
  'customerPhone',
  'messageToReseller',
  'prospectId',
  'selectedReseller',
  'useROT',
];

export const convertToProspectRequest = (prospect: Prospect): ProspectRequest =>
  pick(prospect, prospectRequestKeys);
