import {
  AnswerConfigData,
  ConfigData,
  ProspectAccessoryAttributesBase,
  StockItemData,
} from 'api-client';
import AccordionConfigComponent, {
  AccordionConfigComponentProps,
  isStockItem,
} from 'components/AccordionConfigComponent/AccordionConfigComponent';
import DropdownComponent, {
  DropdownComponentProps,
  DropdownItem,
} from 'components/DropdownComponent/DropdownComponent';
import RadioButtonList from 'components/RadioButtonList/RadioButtonList';
import { useCallback, useMemo } from 'react';
import { useController } from 'react-hook-form';
import { useWindowFormContext } from 'utils/useWindowFormContext';

export type AccessoryNames =
  | 'sunscreen'
  | 'interiorLining'
  | 'exteriorLining'
  | 'windowSill';

export type StockItemConfigComponentProps = Omit<
  AccordionConfigComponentProps,
  'data' | 'children'
> & {
  data?: StockItemData;
  name: `prospectRow.${AccessoryNames}`;
};
const StockItemConfigComponent = ({
  data,
  name,
}: StockItemConfigComponentProps) => {
  const { control } = useWindowFormContext();
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });
  const findInitialItem = useCallback(() => {
    const defaultValue = value;
    if (defaultValue) {
      return data?.stockItemData.find(o => o.id === defaultValue);
    }
  }, [value, data?.stockItemData]);

  const onChangeCallback = useCallback(
    (selectedItem: ConfigData) => {
      if (selectedItem) {
        onChange({ id: selectedItem.id, attributes: [] });
      }
    },
    [onChange]
  );

  const attributes = useMemo(
    () =>
      (isStockItem(data) &&
        data.stockItemData?.find(si => si.id === value?.id)?.attributes) ||
      [],
    [data, value]
  );

  const onAttributeChangeCallback = useCallback(
    (attributeId: number, selectedItem: DropdownItem<number>) => {
      if (selectedItem) {
        const filteredAttributes: Array<ProspectAccessoryAttributesBase> =
          value?.attributes?.filter(a => a.attributeId !== attributeId) ?? [];
        const newAttributeList: Array<ProspectAccessoryAttributesBase> = [
          ...filteredAttributes,
          { answerId: selectedItem.value, attributeId },
        ];
        onChange({ ...value, attributes: newAttributeList });
      }
    },
    [onChange, value]
  );
  return data ? (
    <AccordionConfigComponent data={data}>
      <RadioButtonList
        radioOptions={data.stockItemData}
        onChange={onChangeCallback}
        findInitialItem={findInitialItem}
        large
      />
      <div className="tw-mt-2 tw-gap-4 sm:tw-grid sm:tw-grid-cols-2 md:tw-grid-cols-3">
        {attributes.length > 0 &&
          attributes.map(a => (
            <AttributeDropdownComponent
              key={a.title}
              items={a.answerData}
              label={a.title}
              onChange={onAttributeChangeCallback}
              attributeId={a.id}
            />
          ))}
      </div>
    </AccordionConfigComponent>
  ) : null;
};

type AttributeDropdownComponentProps = Omit<
  DropdownComponentProps<number>,
  'onChange' | 'items'
> & {
  items: AnswerConfigData[];
  attributeId: number;
  onChange(
    attributeId: number,
    newValue: DropdownItem<number> | undefined | null
  ): void;
};
const AttributeDropdownComponent = ({
  attributeId,
  onChange,
  items,
  ...props
}: AttributeDropdownComponentProps) => {
  const onChangeCallback = useCallback(
    (selectedItem: DropdownItem<number>) => {
      if (selectedItem) {
        onChange(attributeId, selectedItem);
      }
    },
    [attributeId, onChange]
  );
  const options = useMemo(
    () =>
      items.map(ad => ({
        name: `${ad.title} ${ad.price ? `( +${ad.price} kr )` : ''}`,
        value: ad.id,
      })),
    [items]
  );
  const findInitialItem = useCallback(() => options?.[0], [options]);
  return (
    <DropdownComponent
      onChange={onChangeCallback}
      items={options}
      findInitialItem={findInitialItem}
      showLabel
      {...props}
    />
  );
};
export default StockItemConfigComponent;
