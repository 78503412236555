import { ProspectRow } from 'api-client';
import { prospectApi } from 'api/api';
import { CenteredSpinner } from 'components/Icons';
import { useMemo } from 'react';
import { useAppSelector } from 'store';
import './ProspectAmountSummary.scss';

export const useProspectFromState = () => {
  const prospectId = useAppSelector(state => state.app.prospectId);

  /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
  const {
    data: prospect,
    isLoading,
    isFetching,
  } = prospectApi.useGetProspectQuery(prospectId!, {});
  return { prospect, isLoading, isFetching };
};

export const ProspectAmountSummary = ({
  isUpdating,
}: {
  isUpdating?: boolean;
}) => {
  const { prospect } = useProspectFromState();
  const totalQuantity = useMemo(
    () =>
      prospect?.rows?.reduce(
        (acc: number, row: ProspectRow) => acc + (row.quantity ?? 0),
        0
      ) ?? 0,
    [prospect]
  );
  return (
    <div className="ProspectAmountSummary tw-relative tw-flex-col tw-justify-between tw-text-sm tw-text-secondary">
      {isUpdating && (
        <div className="tw-absolute tw-h-full tw-w-full">
          <CenteredSpinner wrapperClassName="tw-h-full tw-items-center" />
        </div>
      )}
      <div className="tw-flex tw-justify-between">
        <div>Antal</div>
        {totalQuantity > 0 && (
          <div data-testid="total-amount">{totalQuantity}</div>
        )}
      </div>
      {prospect?.productPrice && (
        <div className="tw-flex tw-justify-between">
          <div>Rekommenderat pris (SEK)</div>
          <div>{prospect.productPrice} kr</div>
        </div>
      )}
      {prospect?.installationPrice && (
        <div className="tw-flex tw-justify-between">
          <div>Montagepris (SEK)</div>
          <div>{prospect.installationPrice} kr</div>
        </div>
      )}
      {prospect?.totalPrice && (
        <div className="tw-flex tw-justify-between">
          <div>Totalt pris (SEK)</div>
          <div>{prospect?.totalPrice} kr</div>
        </div>
      )}
    </div>
  );
};

export default ProspectAmountSummary;
