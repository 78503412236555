import classNames from 'classnames';
import {
  ChangeEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ControllerFieldState } from 'react-hook-form';
import { useDebounce } from 'utils/useDbounce';

export interface InputFieldProps<T> {
  label?: string;
  onChange(newValue: T): void;
  helpText?: string;
  error?: string;
  type?: 'text' | 'number' | 'decimal';
  findInitialValue?(): T | undefined;
  name: string;
  className?: string;
  onBlurEditValue?(value: T): T;
  fieldState?: ControllerFieldState;
  disabled?: boolean;
  debounce?: number;
}
export const InputField = <T extends string | number>({
  label,
  onChange,
  helpText,
  type = 'text',
  name,
  className,
  findInitialValue,
  error,
  onBlurEditValue,
  fieldState,
  disabled,
  debounce = 0,
}: InputFieldProps<T>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [internalValue, setInternalValue] = useState<T>('' as any);

  const [debouncedValue] = useDebounce(internalValue, debounce);
  const isNumber = useMemo(() => type === 'number', [type]);
  const isDecimal = useMemo(() => type === 'decimal', [type]);

  useEffect(() => {
    let newValue;
    if (isNumber || isDecimal) {
      // Don't debounce "unfinished decimals" like "5." wait for "5.6"
      if (!isNaN(Number(debouncedValue))) {
        newValue = debouncedValue;
      } else {
        return;
      }
    } else {
      newValue = debouncedValue;
    }
    if (newValue && !onBlurEditValue) {
      onChange(debouncedValue);
    }
  }, [onChange, debouncedValue, isNumber, isDecimal, onBlurEditValue]);

  const onChangeCallback = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ev => {
      setInternalValue(ev.target.value as T);
    },
    []
  );

  const onBlurCallback = useCallback(() => {
    let newValue = (
      type === 'number' ? Number(internalValue) : internalValue
    ) as T;
    newValue = onBlurEditValue ? onBlurEditValue(newValue) : newValue;

    onChange(newValue);
    setInternalValue(newValue);
  }, [onBlurEditValue, onChange, type, internalValue]);

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    event => {
      if (event.key === 'Enter') {
        onBlurCallback();
      }
      // Don't allow decimals in a "number" field.
      if (isNumber && ['.', ','].includes(event.key)) event.preventDefault();
      if (isDecimal && ['.'].includes(event.key)) event.preventDefault();
      if ((isDecimal || isNumber) && ['e', 'E', '-', '+'].includes(event.key)) {
        event.preventDefault();
      }
    },
    [isDecimal, isNumber, onBlurCallback]
  );

  useEffect(() => {
    if (!fieldState?.isDirty) {
      const initValue = findInitialValue?.();
      if (initValue) {
        setInternalValue(initValue);
        onChange(initValue);
      }
    }
  }, [fieldState?.isDirty, setInternalValue, findInitialValue, onChange]);

  return (
    <div
      className={classNames('InputField tw-w-full', className ? className : '')}
    >
      {label && (
        <label htmlFor={name} className="tw-text-base tw-text-secondary">
          {label}
        </label>
      )}
      <div>
        <input
          data-testid="input"
          value={internalValue}
          id={name}
          type={isNumber || isDecimal ? 'number' : 'text'}
          className={classNames(
            'tw-w-full tw-bg-lightGray tw-px-2 tw-py-2 tw-text-xl',
            error ? 'input-error' : ''
          )}
          onChange={onChangeCallback}
          onBlur={onBlurCallback}
          onKeyDown={handleKeyDown}
          disabled={disabled}
        />
      </div>
      {error ? (
        <div
          data-testid="error-text"
          className="tw-mt-1 tw-text-xs tw-text-error"
        >
          {error}
        </div>
      ) : helpText ? (
        <div
          data-testid="help-text"
          className="tw-mt-1 tw-text-xs tw-text-secondary"
        >
          {helpText}
        </div>
      ) : null}
    </div>
  );
};

export default InputField;
