import { ColorConfigData } from 'api-client';
import { WindowCreatorFormState } from 'components/WindowCreator/RowFormPages';
import { useCallback } from 'react';
import { FieldPath, useController, useFormContext } from 'react-hook-form';
import ColorSquareList, {
  Props as BaseColorSquareListProps,
} from './ColorSquareList';

interface Props extends Omit<BaseColorSquareListProps, 'onChange'> {
  name: FieldPath<WindowCreatorFormState>;
  initialValue?: ColorConfigData;
}

export const FormColorSquareList = ({
  name,
  colorOptions,
  initialValue,
}: Props) => {
  const { control } = useFormContext<WindowCreatorFormState>();
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });
  const findInitialItem = useCallback(() => {
    const defaultValue = initialValue?.id || value;
    if (defaultValue) {
      return colorOptions.find(o => o.id === defaultValue);
    }
  }, [colorOptions, initialValue?.id, value]);

  const onChangeCallback = useCallback(
    (selectedItem: ColorConfigData) => {
      if (selectedItem) {
        onChange(selectedItem.id);
      }
    },
    [onChange]
  );

  return (
    <>
      <ColorSquareList
        colorOptions={colorOptions}
        onChange={onChangeCallback}
        findInitialItem={findInitialItem}
      />
    </>
  );
};
