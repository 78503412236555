import productsApi from 'api/api';
import Accordion from 'components/Accordion/Accordion';
import AccordionContent from 'components/Accordion/AccordionContent/AccordionContent';
import { RadioButtonAccordionConfig } from 'components/AccordionConfigComponent/RadioButtonAccordionConfig';
import StockItemConfigComponent from 'components/AccordionConfigComponent/StockItemConfigComponent';
import { FormColorSquareList } from 'components/ColorSquareList/FormColorSquareList';
import SectionComponent from 'components/SectionComponent/SectionComponent';
import { WindowCreatorFormState } from 'components/WindowCreator/RowFormPages';
import { useFormContext } from 'react-hook-form';
import { useIsMd } from 'utils/useIsMd';
import { hasBaseRowInfo, pickBaseRowInfo } from 'utils/useWindowFormContext';
import './ConfigurationDetailsComponent.scss';

export const ConfigurationDetailsComponent = () => {
  const { watch } = useFormContext<WindowCreatorFormState>();
  const [prospectRow] = watch(['prospectRow']);
  const { data: configData } = productsApi.endpoints.getConfigData.useQuery(
    pickBaseRowInfo(prospectRow),
    {
      skip: !hasBaseRowInfo(prospectRow),
    }
  );
  const isMd = useIsMd();
  if (!configData) return null;

  return (
    <SectionComponent
      title={'Konfigurationsdetaljer'}
      number={8}
      classNames="tw-mt-8 md:tw-mt-0 md:tw-h-full"
      hideNumber={isMd}
      hideDescription={isMd}
    >
      <div className="ConfigurationDetailsComponent">
        {configData.colors && (
          <Accordion heading={configData.colors.title}>
            {configData.colors.insideColors?.colorData?.length > 0 && (
              <AccordionContent
                title={configData.colors.insideColors?.title}
                description={configData.colors.insideColors?.description}
              >
                <FormColorSquareList
                  name="prospectRow.insideCoatingId"
                  colorOptions={configData.colors.insideColors?.colorData}
                />
              </AccordionContent>
            )}
            {configData.colors.outsideColors &&
              configData.colors.outsideColors?.colorData?.length > 0 && (
                <AccordionContent
                  title={configData.colors.outsideColors?.title}
                  description={configData.colors.outsideColors?.description}
                >
                  <FormColorSquareList
                    name="prospectRow.outsideCoatingId"
                    colorOptions={configData.colors.outsideColors.colorData}
                  />
                </AccordionContent>
              )}
          </Accordion>
        )}
        <RadioButtonAccordionConfig
          data={configData.glass}
          name={'prospectRow.glassId'}
        />
        <RadioButtonAccordionConfig
          data={configData.airVent}
          name={'prospectRow.airVentId'}
        />
        <RadioButtonAccordionConfig
          data={configData.hanging}
          name={'prospectRow.hangingId'}
        />
        <RadioButtonAccordionConfig
          data={configData.handle}
          name={'prospectRow.handleId'}
        />
        <RadioButtonAccordionConfig
          data={configData.hinge}
          name={'prospectRow.hingeId'}
        />
        <RadioButtonAccordionConfig
          hideIfOne={true}
          data={configData.alignment}
          name={'prospectRow.alignmentId'}
        />
        <StockItemConfigComponent
          data={configData.sunscreen}
          name={'prospectRow.sunscreen'}
        />
        <RadioButtonAccordionConfig
          data={configData.mosquitoNet}
          name={'prospectRow.mosquitoNetId'}
        />
        <StockItemConfigComponent
          data={configData.exteriorLining}
          name={'prospectRow.exteriorLining'}
        />
        <StockItemConfigComponent
          data={configData.interiorLining}
          name={'prospectRow.interiorLining'}
        />
        <StockItemConfigComponent
          data={configData.windowSill}
          name={'prospectRow.windowSill'}
        />
      </div>
    </SectionComponent>
  );
};

export default ConfigurationDetailsComponent;
