import { Prospect } from 'api-client';
import { prospectApi } from 'api/api';
import InputField from 'components/InputField/InputField';
import { useProspectFromState } from 'components/ProspectAmountSummary/ProspectAmountSummary';
import Button from 'components/Button/Button';
import { useCallback, useState } from 'react';
import './ContactForm.scss';
import {
  AnalyticsItem,
  AnalyticsObject,
  mapItems,
  sendEvent,
} from 'utils/analytics';

export interface Props {
  leadSentCallback(): void;
  selectedReseller?: string;
  selectedResellerName?: string;
  requireReseller?: boolean;
}

export const ContactForm = ({
  leadSentCallback,
  selectedReseller,
  selectedResellerName,
  requireReseller = true,
}: Props) => {
  const [phoneNumber, setPhoneNumber] = useState<string | number>('');
  const [email, setEmail] = useState<string | number>('');
  const [message, setMessage] = useState<string | number>('');
  const [name, setName] = useState<string | number>('');
  const [sendLead, { isLoading }] = prospectApi.useSendLeadMutation();
  const { prospect } = useProspectFromState();

  const disabled =
    !prospect ||
    !(phoneNumber && email && name) ||
    (requireReseller && !selectedReseller);
  const sendLeadCallback = useCallback(async () => {
    if (!prospect) return;
    const updatedProspect: Prospect = {
      ...prospect,
      customerEmail: email as string,
      customerName: name as string,
      customerPhone: phoneNumber as string,
      messageToReseller: message as string,
      selectedReseller,
    };

    sendLead(updatedProspect)
      .unwrap()
      .then(() => {
        leadSentCallback();
        const analyticsObject: AnalyticsObject = {
          retailer: selectedResellerName,
        };

        const analyticsItems: AnalyticsItem[] = prospect.rows
          ? mapItems(prospect.rows)
          : [];
        sendEvent('add_to_cart', analyticsItems, analyticsObject);
      })
      .catch();
  }, [
    email,
    leadSentCallback,
    message,
    phoneNumber,
    name,
    prospect,
    selectedReseller,
    selectedResellerName,
    sendLead,
  ]);

  return (
    <div className="ContactForm">
      <InputField label="Namn*" name="name-field" onChange={setName} />
      <InputField
        label="Mobilnummer*"
        name="phone-field"
        onChange={setPhoneNumber}
      />
      <InputField
        label="Email*"
        name="email-field"
        onChange={setEmail}
        className="tw-mt-2"
      />
      <InputField
        label="Meddelande"
        name="message-field"
        onChange={setMessage}
        className="tw-mt-2"
      />
      {prospect && (
        <div className="tw-mt-4">
          <Button
            onClick={sendLeadCallback}
            fullWidth
            loading={isLoading}
            disabled={disabled}
          >
            Skicka förfrågan!
          </Button>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
