import {
  Renderer,
  WindowRenderComponent,
  WindowRenderData,
} from '@ef/ef-component-library';
import { ProspectRow } from 'api-client';
import productsApi from 'api/api';
import { SpinnerIcon } from 'components/Icons';
import { RefObject, useEffect, useRef, useState } from 'react';
import { useConfiguredRow } from 'utils/useConfiguredRow';
import { hasBaseRowInfo } from 'utils/useWindowFormContext';
import './WindowImageRenderer.scss';

export type Props = {
  row?: ProspectRow;
  loading?: boolean;
  error?: boolean;
};
export function useOnScreen<T extends Element>(ref: RefObject<T>) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    if (ref.current) observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isIntersecting;
}
const CenteredLoading = () => (
  <div className="tw-absolute tw-top-0 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center">
    <SpinnerIcon />
  </div>
);
const CenteredError = () => (
  <div className="tw-absolute tw-top-0 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-bg-white">
    Kunde ej hämta produktbild
  </div>
);

export const FormWindowImageRenderer = () => {
  const { data: row, isLoading, isFetching, isError } = useConfiguredRow();
  return (
    <div className="tw-h-full tw-w-full">
      <WindowImageRenderer
        row={row}
        loading={isLoading || isFetching}
        error={isError}
      />
    </div>
  );
};

export const WindowImageRenderer = ({ row, loading, error }: Props) => {
  const { data, isError, isLoading, isFetching } =
    productsApi.useGetImageJsonQuery(
      { ...row },
      { skip: !hasBaseRowInfo(row) }
    );

  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);
  const [renderer, setRenderer] = useState<Renderer<WindowRenderData>>();
  const [cachedData, setCachedData] = useState(data);
  useEffect(() => {
    if (data) setCachedData(data);
  }, [data]);
  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        renderer?.resize();
        renderer?.zoomToFit(20);
      }, 10);
    }
  }, [isVisible, renderer]);
  return (
    <div
      ref={ref}
      data-testid="window-render-container"
      className="WindowImageRenderer tw-relative tw-m-auto tw-h-full tw-w-full"
    >
      {cachedData && (
        <WindowRenderComponent
          showSpinIcon
          onRendererSetup={r => setRenderer(r)}
          def={cachedData}
          mode="standard"
          objectId={1}
          enableRotate={true}
          showOpeningMarkers={false}
          enableShowSideText
        />
      )}
      {(isLoading || isFetching || loading) && <CenteredLoading />}
      {(isError || error) && <CenteredError />}
    </div>
  );
};

export default WindowImageRenderer;
