import { ProspectRow } from 'api-client';
import {
  AddIcon,
  EditIcon,
  EyeIcon,
  RemoveIcon,
  TrashIcon,
} from 'components/Icons';
import InformationTabsComponent from 'components/InformationTabsComponent/InformationTabsComponent';
import Modal from 'components/Modal/Modal';
import { useCallback } from 'react';
import { useIsMd } from 'utils/useIsMd';
import './ProspectRowSummary.scss';

import 'reactjs-popup/dist/index.css';

export interface Props {
  prospectRow: ProspectRow;
  changeAmount(id: string, newAmount: number): void;
  editCallback(id: string): void;
  deleteCallback(id: string): void;
}
export const ProspectRowSummary = ({
  prospectRow,
  changeAmount,
  deleteCallback,
  editCallback,
}: Props) => {
  const isMd = useIsMd();
  const increment = useCallback(
    () => changeAmount(prospectRow.rowId!, (prospectRow.quantity ?? 0) + 1),
    [changeAmount, prospectRow]
  );
  const decrement = useCallback(
    () => changeAmount(prospectRow.rowId!, (prospectRow.quantity ?? 0) - 1),
    [changeAmount, prospectRow]
  );
  const deletecb = useCallback(
    () => deleteCallback(prospectRow.rowId!),
    [deleteCallback, prospectRow]
  );
  const edit = useCallback(
    () => editCallback(prospectRow.rowId!),
    [editCallback, prospectRow]
  );

  return (
    <div className="ProspectRowSummary tw-flex tw-w-full tw-bg-selected tw-font-semibold">
      <div className="tw-flex tw-grow tw-flex-col tw-justify-between tw-p-2 tw-text-secondary md:tw-text-sm">
        <div>{prospectRow?.productfamilyName}</div>
        <div>{prospectRow?.modelName}</div>
        <div className="tw-flex tw-justify-between">
          <div>
            {`Bredd: ${prospectRow?.width}mm, Höjd: ${prospectRow?.height}mm`}
          </div>
          {prospectRow.price && (
            <div>{`Rekommenderat pris (SEK) - ${prospectRow.price} kr`}</div>
          )}
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-p-2">
        <div className="tw-flex md:tw-text-lg">
          <button
            onClick={increment}
            className="square-button tw-bg-darkGray tw-text-white"
          >
            <AddIcon altName="Öka antal" />
          </button>
          <div className="flex-center tw-aspect-square tw-w-8 tw-bg-white">
            {prospectRow?.quantity}
          </div>
          <button
            onClick={decrement}
            className="square-button tw-bg-secondary tw-text-white"
          >
            <RemoveIcon altName="Minska antal" />
          </button>
        </div>
        <div className="tw-mt-1 tw-flex tw-bg-primaryHover tw-text-white">
          <Modal
            title={'Sammanfattning produkt'}
            trigger={<EyeIcon altName="Inspektera" />}
            content={
              <div className="tw-h-80 tw-w-80">
                <InformationTabsComponent row={prospectRow} />
              </div>
            }
            buttonClass="square-button"
            modal={!isMd}
          />
          <button className="square-button" onClick={deletecb}>
            <TrashIcon altName="Ta bort" />
          </button>
          <button
            className="square-button disabled:tw-bg-[#bbb] disabled:tw-opacity-60"
            onClick={edit}
            title="Kommer snart"
          >
            <EditIcon altName="Redigera" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProspectRowSummary;
