import { ModelsResponse } from 'api-client';
import productsApi from 'api/api';
import SectionComponent from 'components/SectionComponent/SectionComponent';
import { FormSizeSelector } from 'components/SizeSelector/FormSizeSelector';
import { useMemo } from 'react';
import { useWindowFormContext } from 'utils/useWindowFormContext';
import './SizeSection.scss';

export interface Props {
  modelsData: ModelsResponse;
  isMd: boolean;
  initWidth?: number;
  initHeight?: number;
}
export const SizeSection = ({
  isMd,
  modelsData,
  initHeight,
  initWidth,
}: Props) => {
  const { watch } = useWindowFormContext();
  const [modelId, width] = watch(['prospectRow.modelId', 'prospectRow.width']);
  const { size: sizeData, models } = modelsData;
  const model = useMemo(
    () => models.data.find(m => m.value === modelId),
    [models, modelId]
  );

  let minHieght = model?.size.minHeightMm;
  let maxHeight = model?.size.maxHeightMm;

  const { data: sizeValidationData } = productsApi.useValidateWindowSizeQuery(
    { modelId, width },
    { skip: !modelId || !width }
  );
  if (
    sizeValidationData?.data?.MaxHeight?.[0] &&
    sizeValidationData?.data?.MinHeight?.[0]
  ) {
    minHieght = sizeValidationData.data.MinHeight[0];
    maxHeight = sizeValidationData.data.MaxHeight[0];
  }

  return (
    <SectionComponent
      title={sizeData.title ?? 'Modulmått'}
      number={6}
      classNames="tw-mt-8"
      hideNumber={isMd}
      hideDescription={!isMd}
      description={sizeData.description}
      popupInfo={sizeData.infoPopup?.body}
    >
      <FormSizeSelector
        name="prospectRow.width"
        label="Bredd"
        max={model?.size.maxWidthMm}
        min={model?.size.minWidthMm}
        moduleSize={model?.size.moduleWidth}
        initialValue={initWidth ?? model?.size.defaultWidth}
      />
      <FormSizeSelector
        name="prospectRow.height"
        label="Höjd"
        max={maxHeight}
        min={minHieght}
        moduleSize={model?.size.moduleHeight}
        initialValue={initHeight ?? model?.size.defaultHeight}
        className="tw-mt-4"
      />
    </SectionComponent>
  );
};

export default SizeSection;
