import cls from 'classnames';
import InfoIcon from 'components/Icons/InfoIcon';
import Modal from 'components/Modal/Modal';
import { ReactNode } from 'react';
import { useIsMd } from 'utils/useIsMd';
import './SectionComponent.scss';

export interface Props {
  title: string;
  number?: number;
  description?: string | null;
  classNames?: string;
  hideHeading?: boolean;
  hideNumber?: boolean;
  hideDescription?: boolean;
  popupInfo?: ReactNode;
}
export const SectionComponent: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  number,
  description,
  children,
  classNames,
  hideHeading,
  hideNumber,
  hideDescription,
  popupInfo,
}) => {
  const isMd = useIsMd();
  return (
    <div
      className={cls('SectionComponent tw-flex tw-flex-col', classNames ?? '')}
      data-testid="section"
    >
      {!hideHeading && (
        <div className="tw-shrink-0">
          <div className="headline tw-flex tw-items-center tw-text-2xl tw-font-bold md:tw-text-xl">
            {`${
              Number.isInteger(number) && !hideNumber ? `${number}. ` : ''
            }${title}`}
            {popupInfo && (
              <Modal
                title={title}
                trigger={<InfoIcon />}
                content={popupInfo}
                buttonClass="tw-ml-2"
                modal={!isMd}
              />
            )}
          </div>
          {!hideDescription && (
            <div className="description tw-text-xs tw-font-normal tw-text-secondary md:tw-text-base">
              {description}
            </div>
          )}
        </div>
      )}
      <div className={cls({ 'tw-min-h-0 tw-grow tw-pt-4': !hideHeading })}>
        {children}
      </div>
    </div>
  );
};
export default SectionComponent;
