import { FamilyItemDetails } from 'api-client';
import { Product } from 'components/ProductCard/ProductCard';
import { useCallback, useMemo } from 'react';
import { actions, useAppDispatch } from 'store';
import { useWindowFormContext } from 'utils/useWindowFormContext';
import {
  FormProductScroller,
  FormProductScrollerProps,
} from './FormProductScroller';

interface Props extends FormProductScrollerProps {
  name: 'prospectRow.productfamilyid';
  products: FamilyItemDetails[];
}

const filterProducts = (
  products: FamilyItemDetails[],
  materialId: number,
  styleId: number
) =>
  products.filter(
    p =>
      (!materialId || p.materials.includes(materialId)) &&
      (!styleId || p.styles.includes(styleId))
  );

export const FormFamilyProductScroller = ({ products, ...rest }: Props) => {
  const { watch, setValue } = useWindowFormContext();
  const [materialId, styleId] = watch(['material', 'style']);
  const filteredProducts = useMemo(
    () => filterProducts(products, materialId, styleId),
    [materialId, styleId, products]
  );
  const dispatch = useAppDispatch();
  const resetCallback = useCallback(
    (productFamily: Product) => {
      dispatch(actions.setCurrentFamily(productFamily.value));
      setValue('prospectRow.height', undefined);
      setValue('prospectRow.width', undefined);
      setValue('prospectRow.modelCategoryId', undefined);
      setValue('prospectRow.modelId', undefined);
      setValue('prospectRow.sashModelId', undefined);
    },
    [dispatch, setValue]
  );

  return (
    <FormProductScroller
      products={filteredProducts}
      coverImage={true}
      onChangeCallback={resetCallback}
      {...rest}
    />
  );
};
