import classNames from 'classnames';
import './SummaryPageSection.scss';

export interface Props {
  title: string;
  description: string;
  disabled?: boolean;
}
export const SummaryPageSection: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  description,
  children,
  disabled,
}) => {
  return (
    <div className="tw-relative tw-flex tw-h-full tw-flex-col md:tw-justify-between">
      <div>
        <h4 className="tw-my-2 tw-text-xl tw-font-semibold">{title}</h4>
        <p className="tw-mt-6 tw-text-sm tw-text-secondary">{description}</p>
      </div>
      <div className="tw-flex tw-flex-grow tw-flex-col tw-overflow-y-auto">
        {children}
      </div>
      <div
        className={classNames(
          'tw-absolute tw-h-full tw-w-full tw-bg-white tw-opacity-60',
          { 'tw-hidden': !disabled }
        )}
      ></div>
    </div>
  );
};

export default SummaryPageSection;
