import { ResellerItem } from 'api-client';
import classNames from 'classnames';
import './ResellerCard.scss';

export interface Props {
  reseller: ResellerItem;
  setSelected(id: string): void;
  setSelectedName(name: string): void;
  selected?: boolean;
}
export const ResellerCard = ({
  reseller,
  setSelected,
  setSelectedName,
  selected,
}: Props) => (
  <div
    className={classNames('ResellerCard tw-cursor-pointer tw-px-2 tw-py-1', {
      'tw-bg-selected': selected,
      'tw-bg-lightGray': !selected,
    })}
    onClick={() => {
      setSelected(reseller.resellerId);
      setSelectedName(reseller.resellerName + ', ' + reseller.postalAddress);
    }}
  >
    <div className="tw-font-semibold">{reseller?.resellerName}</div>
    <div className="tw-mt-1 tw-text-sm tw-font-light tw-text-secondary">{`${reseller?.address}, ${reseller?.zipCode}, ${reseller?.postalAddress}`}</div>
    <div className="tw-mt-2 tw-text-sm tw-font-light tw-text-secondary">{`${reseller?.distance} km`}</div>
  </div>
);

export default ResellerCard;
