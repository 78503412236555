import { ImageData } from 'api-client';
import classNames from 'classnames';
import { InfoIcon } from 'components/Icons';
import DoneIcon from 'components/Icons/DoneIcon';
import ImageContainer from 'components/ImageContainer/ImageContainer';
import Modal from 'components/Modal/Modal';
import { useCallback, MouseEvent } from 'react';
import { useIsMd } from 'utils/useIsMd';
import './ProductCard.scss';

export type Product = {
  name: string;
  value: number;
  image?: ImageData;
  description?: string | null;
};

export interface Props {
  product: Product;
  selected: boolean;
  onSelectClick(): void;
  coverImage?: boolean;
  hideDescription?: boolean;
}
export const ProductCard = ({
  product,
  selected,
  onSelectClick,
  hideDescription,
  coverImage,
}: Props) => {
  const isMd = useIsMd();

  const onClickCallback = useCallback(
    (e: MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      e.stopPropagation();
      onSelectClick();
    },
    [onSelectClick]
  );
  return (
    <div
      className={classNames(
        'ProductCard tw-w-72 tw-cursor-pointer tw-flex-col tw-bg-lightGray md:tw-mb-1 md:tw-w-60'
      )}
      onClick={onClickCallback}
      data-testid={`card-for-${product.name}`}
    >
      <div className={classNames('image-block tw-h-64 tw-w-full md:tw-h-60')}>
        <ImageContainer
          image={product.image}
          cover={coverImage}
          size={isMd ? 240 : 288} // tw-w-72 md:tw-w-60
        />
      </div>
      <div
        className={classNames(
          'description-block tw-relative tw-flex tw-h-32 tw-bg-lightGray tw-p-4 tw-text-xs',
          {
            'tw-h-24 tw-justify-center': hideDescription,
            'tw-bg-selected': selected,
          }
        )}
      >
        <div
          className={classNames(
            'text-section tw-relative tw-w-12 tw-flex-grow tw-overflow-hidden',
            'before:content-[""] before:tw-absolute before:tw-top-3/4 before:tw-left-0 before:tw-h-1/4 before:tw-w-full before:tw-bg-gradient-to-t',
            'before:tw-from-lightGray',
            {
              'before:tw-from-selected': selected,
              'tw-ml-14 tw-flex tw-justify-center': hideDescription,
            }
          )}
        >
          <div className="tw-flex tw-items-center">
            <label
              className={classNames(
                'title tw-flex tw-cursor-pointer tw-items-center tw-font-bold',
                {
                  'tw-text-base': hideDescription,
                }
              )}
              htmlFor={product.name}
            >
              {product.name}{' '}
              <Modal
                title={product.name}
                trigger={<InfoIcon />}
                content={product.description}
                buttonClass="tw-ml-2"
                modal={!isMd}
              />
            </label>
          </div>
          <div
            className={classNames(
              'description tw-overflow-hidden tw-text-ellipsis tw-pt-2 tw-text-secondary',
              {
                'tw-hidden': hideDescription,
              }
            )}
          >
            {product.description}
          </div>
        </div>
        <div
          className={classNames(
            'button-container tw-ml-2 tw-flex tw-items-end',
            {
              'tw-items-center': hideDescription,
            }
          )}
        >
          <button
            id={product.name}
            onClick={onClickCallback}
            className="selection-button tw-flex tw-h-12 tw-w-12
           tw-items-center tw-justify-center tw-rounded-full tw-bg-white tw-text-success"
          >
            {selected && (
              <span data-testid={'selected-icon'}>
                <DoneIcon />
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
