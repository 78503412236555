import ProductCard, { Product } from 'components/ProductCard/ProductCard';
import { useCallback, useEffect, useState } from 'react';
import { ControllerFieldState } from 'react-hook-form';
import './ProductScroller.scss';

export interface BaseProductScrollerProps {
  products: Product[];
  onChange(value: Product | undefined | null): void;
  findInitialItem?(): Product | undefined;
  fieldState?: ControllerFieldState;
  hideDescription?: boolean;
  coverImage?: boolean;
}

export const ProductScroller = ({
  products,
  onChange,
  findInitialItem,
  hideDescription,
  coverImage,
}: BaseProductScrollerProps) => {
  const [selectedItem, setSelectedItem] = useState<Product>();

  const onItemClick = useCallback(
    (item: Product) => {
      setSelectedItem(item);
      onChange(item);
    },
    [onChange]
  );

  const findAndSelectInitialItem = useCallback(() => {
    const initItem = findInitialItem?.();
    if (initItem) {
      setSelectedItem(initItem);
      onChange(initItem);
    }
  }, [findInitialItem, setSelectedItem, onChange]);
  useEffect(() => {
    // The list of products gets updated or the selected item is updated.
    // Is also triggered when the value is updated because of the findInitialItem dependency
    // which is dependent on the value

    if (!products.find(p => p.value === selectedItem?.value)) {
      findAndSelectInitialItem();
    }
  }, [products, selectedItem, findAndSelectInitialItem]);

  return (
    <div className="ProductScroller">
      <ul className="product-list">
        {products.map((item, index) => (
          <li
            key={`${item}${index}`}
            data-testid="slider-item"
            className="tw-w-72 md:tw-w-60"
          >
            <ProductCard
              key={`${item}${index}`}
              product={item}
              selected={selectedItem?.value === item.value}
              onSelectClick={() => onItemClick(item)}
              hideDescription={hideDescription}
              coverImage={coverImage}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductScroller;
