import classNames from 'classnames';
import { ReactNode, useState } from 'react';
import './TabViewer.scss';

export interface Props {
  tabs: Array<{ label: string; content: ReactNode }>;
}
export const TabViewer = ({ tabs }: Props) => {
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <div className="TabViewer tw-flex tw-h-full tw-grow tw-flex-col">
      <div className="tw-flex">
        {tabs.map((t, idx) => (
          <div
            className={classNames(
              'tw-flex tw-h-10 tw-flex-grow tw-cursor-pointer tw-items-center tw-justify-center tw-bg-white tw-text-center tw-text-xs tw-font-bold tw-text-secondary',
              {
                'tw-bg-lightGray': idx === selectedTab,
              }
            )}
            key={t.label}
            onClick={() => setSelectedTab(idx)}
          >
            {t.label}
          </div>
        ))}
      </div>
      <div className="tw-min-h-0 tw-grow tw-bg-lightGray tw-py-6 tw-px-6 tw-text-sm">
        {tabs[selectedTab].content}
      </div>
    </div>
  );
};

export default TabViewer;
