import classNames from 'classnames';
import ChevronDownIcon from 'components/Icons/ChevronDownIcon';
import { useSelect } from 'downshift';
import { useEffect } from 'react';
import { ControllerFieldState } from 'react-hook-form';
import './DropdownComponent.scss';

export type DropdownItem<T extends string | number> = {
  name: string;
  value: T;
};

export interface DropdownComponentProps<T extends string | number> {
  label: string;
  showLabel?: boolean;
  items: DropdownItem<T>[];
  onChange(value: DropdownItem<T> | undefined | null): void;
  findInitialItem?(): DropdownItem<T> | undefined;
  fieldState?: ControllerFieldState;
}
export const DropdownComponent = <T extends string | number>({
  label,
  items,
  onChange,
  findInitialItem,
  fieldState,
  showLabel = false,
}: DropdownComponentProps<T>) => {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    selectItem,
  } = useSelect({
    items,
    onSelectedItemChange: change => onChange(change.selectedItem),
  });

  useEffect(() => {
    if (!fieldState?.isDirty) {
      const initItem = findInitialItem?.();
      if (initItem) {
        selectItem(initItem);
      }
    }
  }, [fieldState?.isDirty, selectItem, findInitialItem]);

  return (
    <div className="DropdownComponent tw-relative">
      {showLabel && (
        <label className="tw-text-lg tw-font-semibold">{label}</label>
      )}
      <button
        className="tw-flex tw-w-full tw-items-center tw-justify-between tw-bg-lightGray 
        tw-px-5 tw-py-3 tw-text-lg tw-font-semibold"
        {...getToggleButtonProps()}
      >
        <span>{selectedItem?.name || label}</span>
        <span className="tw-flex tw-align-middle">
          <ChevronDownIcon />
        </span>
      </button>
      <ul
        {...getMenuProps()}
        className={classNames(
          'tw-absolute tw-z-10 tw-w-full tw-overflow-y-auto tw-bg-white',
          { 'tw-border tw-border-selected': isOpen }
        )}
      >
        {isOpen &&
          items.map((item, index) => (
            <li
              className={classNames(
                'tw-cursor-pointer tw-px-5 tw-py-3 hover:tw-bg-selected',
                {
                  highlighted: highlightedIndex === index,
                }
              )}
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {item.name}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default DropdownComponent;
