import { WindowCreatorFormState } from 'components/WindowCreator/RowFormPages';
import { useCallback } from 'react';
import { FieldPath, useFormContext } from 'react-hook-form';
import InputField, { InputFieldProps } from './InputField';

export interface FormInputFieldProps<T>
  extends Omit<InputFieldProps<T>, 'onChange' | 'findInitialValue'> {
  name: FieldPath<WindowCreatorFormState>;
  initialValue?: T;
}
// Todo: Make controlled
export const FormInputField = <T extends string | number>({
  name,
  initialValue,
  ...rest
}: FormInputFieldProps<T>) => {
  const { register, setValue, getValues } =
    useFormContext<WindowCreatorFormState>();
  const findInitialValue = useCallback(
    () => initialValue || (getValues(`${name}`) as T),
    [getValues, initialValue, name]
  );

  const onChangeCallback = useCallback(
    (newValue: T) => {
      if (newValue) {
        setValue(`${name}`, newValue);
      }
    },
    [name, setValue]
  );

  return (
    <>
      <input type="hidden" {...register(`${name}`)} />
      <InputField
        onChange={onChangeCallback}
        findInitialValue={findInitialValue}
        name={name}
        {...rest}
      />
    </>
  );
};
