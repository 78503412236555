import { useCallback } from 'react';
import { useController } from 'react-hook-form';
import { useWindowFormContext } from 'utils/useWindowFormContext';
import SizeSelector, { SizeSelectorProps } from './SizeSelector';

export interface FormSizeSelectorProps
  extends Omit<SizeSelectorProps, 'onChange' | 'findInitialValue'> {
  name: 'prospectRow.width' | 'prospectRow.height';
  initialValue?: number;
}
export const FormSizeSelector = ({
  name,
  initialValue,
  ...rest
}: FormSizeSelectorProps) => {
  const { control } = useWindowFormContext();
  const {
    field: { onChange, value },
    fieldState,
  } = useController({
    name,
    control,
  });
  const findInitialValue = useCallback(
    () => initialValue || value,
    [initialValue, value]
  );

  const onChangeCallback = useCallback(
    (newValue: number | string) => {
      if (newValue) {
        onChange(Number(newValue));
      }
    },
    [onChange]
  );

  return (
    <>
      <SizeSelector
        onChange={onChangeCallback}
        findInitialValue={findInitialValue}
        name={name}
        fieldState={fieldState}
        {...rest}
      />
    </>
  );
};
