import Button, { LinkButton } from 'components/Button/Button';
import InputField from 'components/InputField/InputField';
import { PageName } from 'components/ProgressBar/ProgressBar';
import { useCallback, useState } from 'react';
import { selectTheme, useAppSelector } from 'store';
import './SplashScreen.scss';

export interface Props {
  setPageCallback(page: PageName): void;
  fetchProspectFromCode(code: string): Promise<boolean>;
  isLoadingProspect: boolean;
}
export const SplashScreen = ({
  setPageCallback,
  fetchProspectFromCode,
  isLoadingProspect,
}: Props) => {
  const [showCodeInput, setShowCodeInput] = useState(false);

  const [code, setCode] = useState('');

  const continueCallback = useCallback(() => {
    setShowCodeInput(true);
  }, []);

  const startNewCallback = useCallback(() => {
    setPageCallback('style');
  }, [setPageCallback]);

  const setCodeCallback = useCallback((newCode: string | number) => {
    setCode(newCode as string);
  }, []);

  const theme = useAppSelector(selectTheme);

  const getFromCodeCallback = useCallback(async () => {
    const res = await fetchProspectFromCode(code);
    if (res) {
      setShowCodeInput(false);
    }
  }, [code, fetchProspectFromCode]);
  return (
    <div className="tw-h-full md:tw-flex">
      <div className="md:tw-order-2 md:tw-basis-3/5">
        <img
          alt={'Cover image'}
          src={
            'https://www.elitfonster.se/globalassets/startsida-flex/header-start_nov2021.jpg'
          }
          className="tw-object-cover md:tw-h-full"
        />
      </div>
      <div className="tw-flex tw-flex-col tw-justify-between md:tw-order-1 md:tw-basis-2/5">
        <div className="md:tw-mr-5 lg:tw-mr-8">
          <h1 className="tw-text-3xl tw-font-semibold md:tw-text-4xl lg:tw-text-5xl xl:tw-text-7xl">
            Bygg ditt fönster
          </h1>
          <div className="tw-mt-2 md:tw-mt-4 lg:tw-mt-6">
            {showCodeInput ? (
              <>
                Har du designat dina fönster och skapat ett underlag sedan
                tidigare kan du öppna detta igen genom att använda den
                designkoden som du fick för underlaget.
              </>
            ) : (
              <>
                Välkommen till Bygg ditt fönster. Ett designverktyg från
                Elitfönster där du själv enkelt designar dina nya fönster precis
                så som du vill ha dem. <br />
                Testa olika färger och designer för att hitta det som passar dig
                och ditt hus bäst. <br /> När du är redo skickar du in
                underlaget till din lokala återförsäljare som inom kort
                återkommer med en kostnadsfri offert. <br />
                <br />
                Du kan givetvis spara ditt underlag för att fortsätta vid ett
                senare tillfälle.
              </>
            )}
          </div>
        </div>
        <div className="tw-mt-2 tw-text-xl md:tw-mr-4">
          {showCodeInput ? (
            <>
              <div>
                <InputField
                  name="code-input"
                  onChange={setCodeCallback}
                  type="text"
                  label="Ange kod"
                />
              </div>
              <div className="tw-mt-2">
                <Button
                  onClick={getFromCodeCallback}
                  fullWidth
                  disabled={!code}
                  loading={isLoadingProspect}
                >
                  Skicka kod
                </Button>
              </div>
            </>
          ) : (
            <>
              <div>
                <Button onClick={startNewCallback} fullWidth>
                  Bygg nytt fönster
                </Button>
              </div>
              {theme == 'ef-theme' && (
                <div className="tw-mt-2">
                  <LinkButton
                    fullWidth
                    href={
                      'https://www.elitfonster.se/byta-fonster/energiraknaren/'
                    }
                    blank
                  >
                    Energiräknaren
                  </LinkButton>
                </div>
              )}
              <div className="tw-mt-2">
                <Button onClick={continueCallback} fullWidth>
                  Öppna sparad design
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;
