import InputField, { InputFieldProps } from 'components/InputField/InputField';
import { useCallback, useMemo, useState } from 'react';
import './SizeSelector.scss';

export interface SizeSelectorProps
  extends Omit<InputFieldProps<number>, 'error' | 'helpText' | 'type'> {
  min?: number;
  max?: number;
  onChange(newValue: number | string): void;
  shouldRoundSize?: boolean;
  moduleSize?: number;
}
export const SizeSelector = ({
  max,
  min,
  onChange,
  shouldRoundSize = true,
  moduleSize,
  ...rest
}: SizeSelectorProps) => {
  const [value, setValue] = useState<number>(0);
  const helpText = useMemo(() => `Min: ${min}mm - Max: ${max}mm`, [max, min]);
  const error = useMemo(
    () =>
      !value
        ? undefined
        : min && value < min
        ? `Värdet ${value}mm är för litet. Behöver vara minst ${min}mm`
        : max && value > max
        ? `Värdet ${value}mm är för stort. Får vara max ${max}mm`
        : undefined,
    [value, min, max]
  );

  const fromCustomFormat = useCallback(
    (value: number, moduleAdjustment = 0) => {
      if (value >= 80) {
        if (shouldRoundSize) return Math.floor(value / 10) * 10;
        else return value;
      }
      return Math.round(value * 100 - moduleAdjustment);
    },
    [shouldRoundSize]
  );

  const onChangeCallback = useCallback(
    (newValue: number) => {
      onChange(newValue);
      setValue(newValue);
    },
    [onChange]
  );

  const onBlurCallback = useCallback(
    (newValue: number) => fromCustomFormat(newValue, moduleSize),
    [fromCustomFormat, moduleSize]
  );

  return (
    <div className="SizeSelector">
      <InputField
        helpText={helpText}
        error={error}
        type="number"
        {...rest}
        onChange={onChangeCallback}
        onBlurEditValue={onBlurCallback}
      />
    </div>
  );
};

export default SizeSelector;
