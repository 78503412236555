import cls from 'classnames';
import { useCallback, useState } from 'react';
export interface Props {
  label?: string;
  onChange(value: boolean): void;
  initialValue?: boolean;
}
export const ToggleSwitch = ({ label, onChange, initialValue }: Props) => {
  const [toggle, setToggle] = useState(initialValue ?? false);

  const onToggleCallback = useCallback(() => {
    setToggle(prevToggleValue => {
      const newValue = !prevToggleValue;
      onChange(newValue);
      return newValue;
    });
  }, [onChange, setToggle]);
  return (
    <>
      <div className="tw-flex">
        <div
          className="tw-flex tw-grow-0 tw-cursor-pointer tw-items-center tw-justify-center"
          onClick={onToggleCallback}
        >
          <div
            className={cls(
              'tw-flex tw-h-6 tw-w-12 tw-items-center tw-rounded-full tw-bg-lightGray tw-p-1 tw-duration-300 tw-ease-in-out',
              { 'tw-bg-primary': toggle }
            )}
          >
            {/* Switch */}
            <div
              data-testid="dot"
              className={cls(
                'tw-h-5 tw-w-5 tw-translate-x-0 tw-rounded-full tw-bg-white tw-shadow-md tw-duration-300 tw-ease-in-out',
                {
                  'tw-translate-x-5 tw-transform': toggle,
                }
              )}
            ></div>
          </div>
          {label && <div className="tw-ml-2">{label}</div>}
        </div>
      </div>
    </>
  );
};

export default ToggleSwitch;
