import productsApi from 'api/api';
import classNames from 'classnames';
import { FormDropdownComponent } from 'components/DropdownComponent/FormDropdownComponent';
import { CenteredSpinner } from 'components/Icons';
import { FormFamilyProductScroller } from 'components/ProductScroller/FormFamilyProductScroller';
import SectionComponent from 'components/SectionComponent/SectionComponent';
import { useAppSelector } from 'store';
import { useIsMd } from 'utils/useIsMd';

export const StyleScreen = () => {
  const isMd = useIsMd();
  const { data: productFamiliesData } =
    productsApi.endpoints.getProductFamilies.useQuery();

  const initialProductFamilyId = useAppSelector(
    state => state.app.row?.productfamilyid
  );
  return productFamiliesData ? (
    <>
      <div className="tw-overflow-y-auto md:tw-shrink-0 md:tw-basis-72">
        <div className="screen-description tw-hidden tw-font-semibold md:tw-block">
          Välj bland våra olika produktfamiljer, filtret för husstil och
          material hjälper dig vid behov.
        </div>
        {productFamiliesData.styles && (
          <SectionComponent
            description={productFamiliesData.styles.description}
            title={productFamiliesData.styles.title}
            number={1}
            classNames="tw-mt-2"
            hideNumber={isMd}
          >
            <FormDropdownComponent
              addAllOption
              name="style"
              items={productFamiliesData.styles.data}
              label={productFamiliesData.styles.title}
            />
          </SectionComponent>
        )}
        {productFamiliesData.materials && (
          <SectionComponent
            description={productFamiliesData.materials.description}
            title={productFamiliesData.materials.title}
            number={2}
            classNames="tw-mt-8"
            hideNumber={isMd}
          >
            <FormDropdownComponent
              addAllOption
              name="material"
              items={productFamiliesData.materials.data}
              label={productFamiliesData.materials.title}
            />
          </SectionComponent>
        )}
      </div>
      <div className={classNames('tw-min-w-0 md:tw-ml-8 md:tw-flex-grow')}>
        <SectionComponent
          description={productFamiliesData.families.description}
          title={productFamiliesData.families.title ?? 'Produktfamilj'}
          number={3}
          classNames="tw-mt-8 md:tw-mt-0"
          hideHeading={isMd}
          hideNumber={isMd}
        >
          <div className="tw--ml-6 tw-w-[calc(100%_+_3rem)] md:tw-ml-0 md:tw-w-auto">
            <FormFamilyProductScroller
              initialValue={initialProductFamilyId}
              products={productFamiliesData.families.data}
              name="prospectRow.productfamilyid"
            />
          </div>
        </SectionComponent>
      </div>
    </>
  ) : (
    <CenteredSpinner size={48} wrapperClassName="tw-mt-48" />
  );
};
