import { WindowCreatorFormState } from 'components/WindowCreator/RowFormPages';
import { useCallback, useMemo } from 'react';
import { useController } from 'react-hook-form';
import { useWindowFormContext } from 'utils/useWindowFormContext';
import DropdownComponent, {
  DropdownItem,
  DropdownComponentProps as BaseDropdownProps,
} from './DropdownComponent';

interface Props<T extends string | number>
  extends Omit<BaseDropdownProps<T>, 'onChange'> {
  name: keyof WindowCreatorFormState;
  initialValue?: string | number;
  addAllOption?: boolean;
}
// Todo: Convert to controlled component
export const FormDropdownComponent = <T extends string | number>({
  name,
  label,
  items,
  initialValue,
  addAllOption,
}: Props<T>) => {
  const { control } = useWindowFormContext();
  const {
    field: { onChange, value },
    fieldState,
  } = useController({
    name,
    control,
    defaultValue: initialValue ?? addAllOption ? 0 : undefined,
  });
  const allItems = useMemo(
    () =>
      addAllOption
        ? ([{ value: 0, name: 'Alla' }, ...items] as DropdownItem<T>[])
        : items,
    [items, addAllOption]
  );

  const findInitialItem = useCallback(() => {
    const defaultValue = initialValue || value;
    return allItems.find(o => o.value === defaultValue);
  }, [allItems, value, initialValue]);

  const onChangeCallback = useCallback(
    (selectedItem: DropdownItem<T>) => {
      if (selectedItem) {
        onChange(selectedItem.value);
      }
    },
    [onChange]
  );

  return (
    <>
      <DropdownComponent
        items={allItems}
        label={label}
        onChange={onChangeCallback}
        findInitialItem={findInitialItem}
        fieldState={fieldState}
      />
    </>
  );
};
