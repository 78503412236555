import classNames from 'classnames';
import { SpinnerIcon } from 'components/Icons';
import './Button.scss';

export interface ButtonStyleProps {
  rounded?: boolean;
  fullWidth?: boolean;
  variant?: 'primary' | 'secondary' | 'outline';
  className?: string;
  noPadding?: boolean;
  loading?: boolean;
}
export interface ButtonProps extends ButtonStyleProps {
  disabled?: boolean;
  onClick(): void;
}

export const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  disabled,
  onClick,
  rounded,
  fullWidth,
  variant = 'primary',
  className = '',
  noPadding,
  loading,
}) => (
  <button
    className={classNames(
      'tw-flex tw-justify-center tw-px-5 tw-py-3 tw-text-primaryButtonText tw-transition disabled:tw-opacity-60 xl:tw-px-16',
      {
        'tw-rounded-full': rounded,
        'tw-w-full': fullWidth,
        'tw-bg-primary hover:tw-bg-primaryHover disabled:hover:tw-bg-primary':
          variant === 'primary',
        'tw-bg-secondary tw-text-secondaryButtonText hover:tw-bg-secondaryHover disabled:hover:tw-bg-secondary':
          variant === 'secondary',
        'tw-text-secondary hover:tw-text-secondaryHover disabled:hover:tw-text-secondary':
          variant === 'outline',
        'tw-px-0 tw-py-0': noPadding,
        'tw-cursor-default tw-px-12': loading, // Padding decreased to make room for spinner
      },
      className
    )}
    disabled={disabled || loading}
    onClick={loading ? undefined : onClick}
  >
    {children}
    {loading ? <SpinnerIcon className="tw-ml-2" /> : ''}
  </button>
);

export const LinkButton: React.FC<
  React.PropsWithChildren<ButtonStyleProps & { href: string; blank?: boolean }>
> = ({
  children,
  rounded,
  fullWidth,
  variant = 'primary',
  className = '',
  noPadding,
  loading,
  href,
  blank,
}) => (
  <a
    className={classNames(
      'tw-flex tw-cursor-pointer tw-justify-center tw-rounded tw-px-16 tw-py-3 tw-text-primaryButtonText disabled:tw-opacity-60',
      {
        'tw-rounded-full': rounded,
        'tw-w-full': fullWidth,
        'tw-bg-primary hover:tw-bg-primaryHover disabled:hover:tw-bg-primary':
          variant === 'primary',
        'tw-bg-secondary tw-text-secondaryButtonText hover:tw-bg-secondaryHover disabled:hover:tw-bg-secondary':
          variant === 'secondary',
        'tw-text-secondary hover:tw-text-secondaryHover disabled:hover:tw-text-secondary':
          variant === 'outline',
        'tw-px-0 tw-py-0': noPadding,
        'tw-cursor-default tw-px-12': loading, // Padding decreased to make room for spinner
      },
      className
    )}
    href={href}
    target={blank ? '_blank' : '_self'}
    rel="noreferrer"
  >
    {children}
    {loading ? <SpinnerIcon className="tw-ml-2" /> : ''}
  </a>
);

export default Button;
