import './Modal.scss';
import Popup from 'reactjs-popup';
import { ReactNode, useState } from 'react';
import classNames from 'classnames';
import Button from 'components/Button/Button';
import { selectTheme, useAppSelector } from 'store';

export interface Props {
  content: ReactNode;
  trigger: ReactNode;
  title?: string;
  buttonClass?: string;
  modal?: boolean;
}

export interface ModalContentProps
  extends Pick<Props, 'title' | 'content' | 'modal'> {
  close: () => void;
}
export const ModalContent = ({
  title,
  content,
  modal,
  close,
}: ModalContentProps) => {
  const theme = useAppSelector(selectTheme);
  return (
    <div className={theme}>
      <div className="modal tw-relative tw-flex tw-max-w-xs tw-flex-col tw-p-4 md:tw-max-w-xl">
        {modal && (
          <button className="close" onClick={close}>
            &times;
          </button>
        )}
        {title && (
          <div className="header tw-text-xl tw-font-semibold">{title}</div>
        )}
        <div className="content tw-mt-2 tw-bg-white">{content}</div>
        <div className="actions tw-my-2 tw-flex tw-justify-center tw-justify-self-end">
          <Button onClick={close} className="tw-bg-primary">
            Stäng
          </Button>
        </div>
      </div>
    </div>
  );
};
export const Modal = ({ trigger, buttonClass, ...rest }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Popup
      trigger={
        <button className={classNames('button', buttonClass)}>{trigger}</button>
      }
      position={['top center', 'bottom center']}
      keepTooltipInside
      modal={rest.modal}
      onOpen={() => setIsOpen(true)}
      open={isOpen}
      nested
      contentStyle={{ zIndex: 1200, width: 'auto' }}
    >
      <ModalContent {...rest} close={() => setIsOpen(false)} />
    </Popup>
  );
};
export default Modal;
