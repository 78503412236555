import productsApi from 'api/api';
import Accordion from 'components/Accordion/Accordion';
import AccordionContent from 'components/Accordion/AccordionContent/AccordionContent';
import { FormDropdownComponent } from 'components/DropdownComponent/FormDropdownComponent';
import { FormRadioButtonList } from 'components/RadioButtonList/FormRadioButtonList';
import SectionComponent from 'components/SectionComponent/SectionComponent';
import { pick } from 'lodash';
import { useMemo } from 'react';
import { useIsMd } from 'utils/useIsMd';
import {
  hasRequiredSashFields,
  requiredSashFields,
  useWindowFormContext,
} from 'utils/useWindowFormContext';
import './SashModelSelector.scss';

export const SashModelSelector = () => {
  const isMd = useIsMd();
  const { watch } = useWindowFormContext();
  const [prospectRow, dividerType] = watch(['prospectRow', 'dividerType']);
  const { data: sashModelData } = productsApi.useGetSashModelDataQuery(
    pick(prospectRow, requiredSashFields),
    {
      skip: !hasRequiredSashFields(prospectRow),
    }
  );

  const filteredSashModels = useMemo(
    () =>
      sashModelData?.sashModel.data.filter(
        sm => !dividerType || sm.typeId === dividerType
      ) || [],
    [dividerType, sashModelData?.sashModel.data]
  );

  const dividerTypeOptions = useMemo(
    () =>
      sashModelData?.dividerTypeFilter.map(dt => ({
        name: dt.name,
        value: dt.id!,
      })) || [],
    [sashModelData]
  );
  if (!sashModelData) return null;
  return (
    <>
      <SectionComponent
        title={sashModelData.sashModel.title}
        number={7}
        classNames="tw-mt-8 md:tw-mt-0 md:tw-h-full"
        hideNumber={isMd}
        description={sashModelData.sashModel.description}
        hideDescription={isMd}
        //Todo: Ta bort när API levererar texten
        popupInfo={
          sashModelData.sashModel.infoPopup?.body ??
          'Välj spröjs och poster efter husets stil. Nedan väljer du mellan de vanligaste alternativen. Om du behöver sortera mellan de olika typerna välj typ i listan.'
        }
      >
        {isMd ? (
          <div className="tw-flex tw-flex-col md:tw-max-h-full md:tw-grow">
            <div className="tw-shrink-0">
              <FormDropdownComponent
                addAllOption
                name="dividerType"
                items={dividerTypeOptions}
                label={'Spröjstyp'}
              />
            </div>
            <div className="tw-mt-4 tw-overflow-auto">
              <FormRadioButtonList
                name="prospectRow.sashModelId"
                radioOptions={filteredSashModels}
                large
              />
            </div>
          </div>
        ) : (
          <Accordion
            heading={sashModelData.sashModel.title}
            initialIsActive={true}
          >
            <AccordionContent>
              <FormDropdownComponent
                addAllOption
                name="dividerType"
                items={dividerTypeOptions}
                label={'Spröjstyp'}
              />
              <div className="tw-mt-4">
                <FormRadioButtonList
                  name="prospectRow.sashModelId"
                  radioOptions={filteredSashModels}
                  large
                />
              </div>
            </AccordionContent>
          </Accordion>
        )}
      </SectionComponent>
    </>
  );
};

export default SashModelSelector;
