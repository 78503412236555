import { useCallback, useEffect, useState } from 'react';
import './ColorSquareList.scss';
import ColorSquare from './ColorSquare/ColorSquare';
import { ColorConfigData } from 'api-client';

export interface Props {
  colorOptions: ColorConfigData[];
  onChange(value: ColorConfigData | undefined | null): void;
  findInitialItem?(): ColorConfigData | undefined;
}
export const ColorSquareList = ({
  colorOptions,
  onChange,
  findInitialItem,
}: Props) => {
  const [selectedColor, setSelectedColor] = useState<ColorConfigData>();

  const onItemClick = useCallback(
    (item: ColorConfigData) => {
      setSelectedColor(item);
      onChange(item);
    },
    [onChange]
  );

  useEffect(() => {
    if (!colorOptions.find(p => p === selectedColor)) {
      const i = findInitialItem?.() ?? colorOptions[0];

      setSelectedColor(i);
      onChange(i);
    }
  }, [colorOptions, selectedColor, findInitialItem, onChange]);
  return (
    <div className="ColorSquareList tw-grid tw-auto-cols-max tw-grid-flow-col tw-gap-2">
      {colorOptions?.map(color => (
        <ColorSquare
          key={`${color.code}`}
          color={color}
          onSelectClick={() => onItemClick(color)}
          isSelected={color === selectedColor}
        />
      ))}
    </div>
  );
};

export default ColorSquareList;
