import { ProspectRow } from 'api-client';
import { ConfigStatusType } from 'components/ProgressBar/ProgressBar';

declare global {
  /*eslint-disable */
  interface Window {
    dataLayer: any;
  }
  /*eslint-enable */
}

export type AnalyticsItem = {
  item_id?: string;
  item_name?: string | null;
  price?: number;
  quantity?: number;
  item_category?: string | null;
  currency?: string;
};

export type AnalyticsObject = {
  event?: string;
  retailer?: string;
  configStatus?: ConfigStatusType;
};

export function mapItems(items?: Array<ProspectRow>): Array<AnalyticsItem> {
  if (!items) {
    return [];
  }
  return items.map((item: ProspectRow) => {
    const analyticsItem: AnalyticsItem = {
      item_name: item.modelName + ', ' + item.width + 'X' + item.height,
      quantity: item.quantity,
      item_category: item.productfamilyName,
    };

    return analyticsItem;
  });
}

export function sendEvent(
  event: string,
  items?: Array<AnalyticsItem>,
  properties?: AnalyticsObject
): void {
  if (typeof window !== 'undefined') {
    properties = {
      ...properties,
      event: event,
    };

    if (typeof window.dataLayer !== 'undefined') {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        ...properties,
        ecommerce: {
          items: items,
        },
      });
    }
  }
}
