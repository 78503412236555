import { FC } from 'react';

// https://fonts.google.com/icons
export interface IconProps {
  color?: string;
  className?: string;
  altName?: string;
  size?: 24 | 48 | 96;
}

export const SVGIcon: FC<React.PropsWithChildren<IconProps>> = ({
  color,
  className,
  children,
  altName,
  size = 24,
}) => (
  <>
    <svg
      role={'icon'}
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      className={className ? className : ''}
      fill={color ? color : 'currentColor'}
    >
      <title>{altName}</title>
      {children}
    </svg>
  </>
);

export default SVGIcon;
