/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * 0 = None, 1 = One, 2 = Two
 *
 * 0 = None, 1 = One, 2 = Two
 */
export enum ROTEnum {
  '_0' = 0,
  '_1' = 1,
  '_2' = 2,
}
